// $card-shadow: rgba(17, 17, 26, 0.1) 0 3px 3px 0;
$card-shadow: None;

.app-cell-container {

  .input-output-cell-headline-container {
    display: flex;
    align-items: center;

    .input-output-cell-headline-left {
      display: flex;
      align-items: center;
      min-width: 180px; // To vertically align all buttons of input / output cells (looks better when an input and output cell are put above each other)

      .visual-input-headline,
      .visual-output-headline {
        display: flex;
        align-items: center;
        padding: 0;

        span {
          font-family: $Roboto;
          display: inline-block;
          vertical-align: middle;
          font-weight: 400;
          font-size: 15px;
        }
      }

      .visual-input-amount-indicator {
        height: 20px;
        min-width: 20px;
        border-radius: 10px;
        background-color: $color-dark-grey;
        margin-left: 5px;
        cursor: default;

        span {
          font-family: $Roboto;
          color: white;
          font-weight: 400;
          font-size: 12px;
          padding: 3px 5px;
          display: block;
          min-width: 20px;
          line-height: 14px;
          text-align: center;
        }
      }
    }

    .input-output-cell-headline-right {
      display: flex;
      align-items: center;
    }
  }


  .workbench-button {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text;
    margin: 0 5px 0 0;

    &:hover {
      background-color: $color-grey;
      color: $color-white;
    }

    &.disabled {
      color: $color-grey;

      &:hover {
        background-color: $color-white;
        color: $color-grey;
      }
    }
  }

  hr {
    margin: 7px 0;
    border-color: $color-grey;
    max-width: unset;
  }
}

// --- Visual input
.input-element {
  display: flex;
  // margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  margin: 5px;
  box-shadow: $card-shadow;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .input-element-buttons {
    border-top: 1px solid $color-light-grey;
    border-left: 1px solid $color-light-grey;
    border-bottom: 1px solid $color-light-grey;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 5px;
    margin-right: -4px; // To "overlap" the border-radius of the .input-element-container

    .input-element-icon-parent {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px 5px 0;
      cursor: default;
    }
  }

  .input-element-body {
    display: flex;
    width: 0;
    flex-grow: 1;

    .input-element-source {
      margin-left: -4px; // To "overlap" the border-radius of the .input-element-container
      padding: 10px 10px 10px 14px;
      border-top: 1px solid $color-light-grey;
      border-right: 1px solid $color-light-grey;
      border-bottom: 1px solid $color-light-grey;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      font-family: $font-monospace;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      max-width: 100%;
      max-height: 150px;
    }
  }

  .input-element-container {
    background-color: $color-very-light-grey;
    border: 1px solid $color-light-grey;
    border-radius: 4px;
    padding: 10px;
    width: 280px;
    display: flex;
    flex-direction: column;

    .input-element-label {
      cursor: default;
      font-weight: 500;
      font-size: 14px;
      padding: 0;
      margin-bottom: 5px;
    }

    .input-element-description {
      cursor: default;
      font-size: 14px;
      padding: 0;
      margin-bottom: 5px;
    }

    .input-element-error {
      cursor: default;
      color: $color-red;
      padding: 0;
      margin-bottom: 5px;
    }

    .inner-input-element { // --- CUSTOM STYLES FOR THE ELEMENTS
      // --- Text Input
      &.text-input-element {
        min-width: 200px;
      }

      &.number-input-element {
        min-width: 200px;
      }

      &.numeric-slider-element {
        margin-top: 10px;

        .value-preview {
          background-color: $color-light-grey;
          border: 1px solid #bbb;
          border-radius: 4px;
          padding: 14px 20px 14px 20px;
          cursor: default;
          height: 46px;
          font-size: 15px;
        }

        .numeric-slider {
          margin-top: 10px;
        }
      }

      &.check-box-element {
        .checkbox-item {
          margin: 10px 0;
          width: 24px;
          height: 24px;
        }
      }

      &.toggle-element {
        margin-top: 10px;
      }

      &.dropdown-select-element {
        margin-top: 10px;
      }

      &.radio-buttons-element {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      }

      &.multiple-select-element {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .checkbox-item-parent {
          display: flex;
          width: 100%;
          margin-bottom: 7px;

          .checkbox-item {
            margin: 0 0 5px 0;
            height: 24px;
            width: 24px;
          }

          .name {
            margin-left: 5px;
            font-size: 15px;
          }
        }
      }

      &.upload {
        padding: 10px;
      }
    }
  }

  &.upload,
  &.selectable-scatter-plot,
  &.editable-table {
    // The upload selectable-scatter-plot and editable-table elements require more space than the other input elements.
    // So 1. it get's full width + displays the source with a horizontal split (flex-direction:column)
    .input-element-body {
      flex-direction: column;

      .input-element-container {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }

      .input-element-source {
        border-top-right-radius: 0;
      }
    }
  }
}

// --- Visual output
.output-element-parent {
  display: flex;
  margin: 5px;
  box-shadow: $card-shadow;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.code-output {
    .output-element-container {
      width: 0;
      flex-grow: 1;
      padding: 0;
      background-color: unset;
      border-radius: unset;
    }
  }

  &.download-file {
    .output-element-container {
      font-family: $Roboto;
      padding: 5px 20px 20px 10px; // To properly center the button

      .output-element-download-button {
        margin-top: 15px;
      }

      .output-element-not-found-error {
        margin-top: 15px;
        color: $color-red;
      }
    }
  }

  .output-element-buttons {
    border-top: 1px solid $color-light-grey;
    border-left: 1px solid $color-light-grey;
    border-bottom: 1px solid $color-light-grey;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 5px;
    margin-right: -4px; // To "overlap" the border-radius of the .input-element-container

    .output-element-icon-parent {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px 5px 0;
      cursor: default;
    }
  }

  .output-element-container {
    background-color: $color-very-light-grey;
    border: 1px solid $color-light-grey;
    border-radius: 4px;
    padding: 10px;

    .output-element-label {
      cursor: default;
      font-weight: 500;
      font-size: 14px;
      padding: 0;
      margin-bottom: 5px;
    }

    .output-element-description {
      cursor: default;
      font-size: 14px;
      padding: 0;
      margin-bottom: 5px;
    }

    .editor-parent-container {
      margin: -1px; // To overlap the border
      padding-top: 0;

      .editor-container {
        .code-cell-editor {
          min-height: 32px;
        }
      }
    }

    .output-container-parent {
      margin: 0 -1px -1px -1px;
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay--after-open {
    z-index: 10; // To put it above the editor code

    .modal-parent {
      min-width: 400px;

      .element-name {
        color: $color-text;
        font-size: 15px;
        font-weight: 500;
      }

      .modal-body {
        display: flex;
        padding-top: 20px; // Making space for the X-button

        .modal-body-headline {
          color: $color-text;
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        .modal-body-left, .modal-body-right {
          border: 1px solid $color-grey;
          padding: 10px;
          border-radius: 4px;
          background-color: $color-very-light-grey;
        }

        .modal-body-left {
          width: 100%;
          min-width: 400px;

          .input-row {
            display: flex; // To make single- as well as multi-column rows align properly

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .input-col {
              width: 100%;

              &:not(:last-child) { // Is this combination of not last-child and not first-child really the only way to achieve symmetric space truly between the elements?
                margin-right: 10px;
              }

              &:not(:first-child) {
                margin-left: 10px;
              }

              &.error {
                input {
                  border: 1px solid $color-red;
                }
              }

              .form--headline {
                display: flex;

                .form--label {
                  margin-bottom: 5px;
                  padding-bottom: 0;
                }

                .form--error {
                  color: $color-red;
                  margin-bottom: 5px;
                  padding-bottom: 0;
                  margin-left: auto;
                }
              }

              .form--input-parent {
                width: 100%;
                display: flex;


                .use-variable-button {
                  z-index: 2;
                  background-color: $color-white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 36px;
                  height: 46px;
                  border: 1px solid #bbb;
                  border-radius: 4px;
                  margin-left: 5px;
                  flex-shrink: 0;
                  cursor: pointer;

                  svg {
                    transition: color 0.25s ease-out;

                    path {
                      transition: fill 0.25s ease-out;
                    }
                  }

                  &.origin-input {
                    svg {
                      color: $color-grey;
                    }

                    &:hover {
                      svg {
                        color: darken($color-grey, 20%);
                      }
                    }
                  }

                  &.origin-variable {
                    svg {
                      color: $color-primary;

                      path {
                        fill: $color-primary;
                      }
                    }

                    &:hover {
                      svg {
                        color: $color-primary-lighter60;

                        path {
                          fill: $color-primary-lighter60;
                        }
                      }
                    }
                  }
                }
              }

              .Select {
                width: 100%;
              }

              .radio-buttons-element {
                display: flex;
                flex-direction: column;
              }

              .multiple-select-element {
                margin-top: 10px;
                display: flex;
                flex-direction: column;

                .checkbox-item-parent {
                  display: flex;
                  width: 100%;
                  margin-bottom: 7px;

                  .checkbox-item {
                    margin: 0 0 5px 0;
                    height: 24px;
                    width: 24px;
                  }

                  .name {
                    margin-left: 5px;
                    font-size: 15px;
                  }
                }
              }

              .checkbox-item {
                min-height: 20px;
              }
            }
          }

          .horizonal-separator {
            width: 100%;
            height: 1px;
            background-color: $color-grey;
            margin-bottom: 10px;
          }

        }

        .modal-body-right {
          margin-left: 20px;

          .variables-parent {
            border: 1px solid #bbb;
            border-radius: 4px;
            background-color: $color-white;
            display: block;

            .variables-list {

              .ct-headline {
                background-color: $color-white;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }

              .valid-variable {
                cursor: pointer;
              }

              .invalid-variable {
                cursor: not-allowed;
                color: $color-text-light;
              }

              .highlight-row {
                background-color: $color-primary;
                color: $color-white;
              }

              .ct-row {
                // Otherwise JSON-Rows are becoming to high
                max-height: 70px;
              }

              .ct-col-parsed-value {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .buttons-parent {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            .button-cancel {
              margin-left: auto;
            }
          }
        }
      }

      .modal-footer {
        margin-top: 15px;
        width: 100%;
        display: flex;

        .button {
          &:first-child {
            margin-left: auto;
          }

          &:not(:first-child) {
            margin-left: 13px;
          }
        }
      }
    }
  }
}

.modal-select-parent {
  min-width: 630px; // adjust later to be responsive
  max-width: 630px; // adjust later to be responsive
}

.modal-select-elements {
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px; // Space for the X-icon
  // margin: -10px; // Causes stupid scrolling

  .selectable-element {
    width: 200px;
    height: 50px;
    border: 1px solid $color-grey;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $color-light-grey;
    }

    .element-icon {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }

    .element-name {
      text-align: center;
      // margin-top: 12px;
    }
  }
}

.variables-parent,
.elements-parent {
  .empty {
    padding: 10px 10px 10px 45px;
    font-family: "Roboto", sans-serif;
    display: inline-block;
  }
}

.variables-parent {
  display: flex;
  flex-wrap: wrap;
  font-family: "Roboto", sans-serif;

  .variable-card {
    margin: 5px;
    display: flex;
    flex-direction: row;
    width: 322px;
    border-radius: 4px;
    box-shadow: $card-shadow;

    .variable-card-buttons {
      // background-color: lightcoral;
      border-top: 1px solid $color-light-grey;
      border-left: 1px solid $color-light-grey;
      border-bottom: 1px solid $color-light-grey;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 5px;
      margin-right: -4px; // To "overlap" the border-radius of the .input-element-container

      .variable-icon-parent {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px 5px 0;
        cursor: default;
      }
    }

    .variable-card-fields {
      // background-color: lightseagreen;
      background-color: $color-very-light-grey;
      border: 1px solid $color-light-grey;
      border-radius: 4px;
      padding: 10px;
      width: 280px;

      .variable-name-parent {
        margin-bottom: 5px;

        input {
          color: $color-text;
          font-family: $font-monospace;
          font-size: 13px;
        }
      }

      .variable-type-parent {
      }

      .variable-override-parent {
        margin-top: 10px;
        display: flex;
        align-items: center;

        .checkbox-item {
          margin: 0 10px 0 0;
          width: 20px;
          height: 20px;
        }
      }

      .variable-value-parent {
        cursor: default;
        margin-top: 5px;
        border: 1px solid $color-grey;
        border-radius: 4px;
        padding: 13px 18px 10px 18px;
        height: 46px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .variable-error-parent {
        cursor: default;
        margin-top: 5px;
        border: 1px solid $color-red;
        border-radius: 4px;
        padding: 13px 18px 10px 18px;
        height: 46px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $color-red;
      }
    }
  }
}
