@import '../../../../../scss/base/var';

.PieChart {
  height: 100%;
  display: flex;
  flex-direction: column;

  .PieChart--title {
    color: $color-text;
    font-weight: 400;
    margin: 0 0 13px 0;
    font-size: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .PieChart--container {
    padding: 5px;
    height: 1px;
    flex-grow: 1;
    display: flex;

    &.PieChart--with-border {
      border: 1px solid $color-grey;
      border-radius: 4px;
    }

    &.PieChart--with-title {

    }

    .PieChart--chart {
      width: 50%;
      flex-grow: 1;
    }

    .PieChart--legend {
      width: 50%;
      flex-grow: 1;
      padding: 0 0 5px 25px;
      font-size: 11px;
      color: $color-text-light;
      display: flex;
      flex-direction: column;
      justify-content: center;

      li {
        padding: 4px 0;
      }

      .PieChart--legend-dot {
        width: 10px;
        height: 10px;
        vertical-align: top;
        border-radius: 50%;
        display: inline-block;
        margin: 2px 5px 0 0;
      }
    }
  }

  .PieChart--link {
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
