.pre-queue-empty-pic {
  background: url("../../img/orchestration/preQueueEmpty.png") no-repeat;
}

.execution-queue-empty-pic {
  background: url("../../img/orchestration/exQueueEmpty.png") no-repeat;
}

.orchestration-history-empty-pic {
  background: url("../../img/orchestration/historyEmpty.png") no-repeat;
}

.schedules-empty-pic {
  background: url("../../img/orchestration/schedulesEmpty.png") no-repeat;
}

.realtime-empty-pic {
  background: url("../../img/orchestration/realtimeEmpty.png") no-repeat;
}

.wait-for-augur-pic {
  background: url("../../img/graphWait.png") no-repeat;
}

.data-sources-empty-pic {
  background: url("../../img/dataSourcesEmpty.png") no-repeat;
}

.data-history-empty-pic {
  background: url("../../img/noTransferHistory.png") no-repeat;
}

.git-repositories-empty-pic {
  background: url("../../img/noGitRepositories.png") no-repeat;
}

.git-groups-empty-pic {
  background: url("../../img/noGitGroups.png") no-repeat;
}

.resources-no-workbench-pic {
  background: url("../../img/noRunningWorkbench.png") no-repeat;
}

.resources-no-apps-pic {
  background: url("../../img/noRunningApps.png") no-repeat;
}

.augurs--archive--pic {
  background: url("../../img/wait-archive.png") no-repeat;
}

.augur--pic {
  background: url("../../img/wait-augur.png") no-repeat;
}

.connection--pic {
  background: url("../../img/wait-connection.png") no-repeat;
}

.statistics-empty-pic {
  background: url("../../img/statisticsEmpty.png") no-repeat;
}

.error--pic {
  background: url("../../img/error.png") no-repeat;
}

.model-management-empty-pic {
  background: url("../../img/modelManagmentEmpty.png") no-repeat;
}
