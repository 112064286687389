@import '../base/var';

.MainContainer.Orchestration {


  .AddSchedule {
    .form-row-job-group {
      .form--left {
        float: none; //Makes the left and right parts listed underneath instead of next to each other
      }

      .form--right {
        float: none;
        width: 100%;
        margin-left: 60px; // To make it match the title of the form (the number on the left takes 75px, 15 of them are padding)
      }
    }
  }







}

// TODO The things going on here are specializations for the Augur / Datapool list (shown in the "Model Management").
//  There should be ONE common way of doing it.
.JobPicker {
  .Wizard--inner {
    max-width: unset; // The Job picker needs more space than the max 900px
  }

  .job-picker-habitat {
    .augurs--item--content {
      cursor: default;

      &:hover {
        .datapools--item--title,
        .augurs--item--title {
          text-decoration: none;
        }
      }
    }

    .inline-list--item {
      cursor: pointer;
      &.active {
        cursor: pointer;
      }
      &:last-child {
        border-right: 1px solid $color-grey;
      }
    }

    .job-picker-button-list {
      margin: -3px auto;
      display: flex;

      .job-picker-li {
        flex: none;
        margin-left: 5px;

        &:first-child {
          margin: 0 0 0 auto;
        }

        .job-picker-button {
          display: block;
          padding: 4px 12px; //Defines the height of the button
        }
      }
    }

    .datapools-wrap-table {
      padding: 0 11px 20px;

      .job-picker-button-list {
        margin: 0 auto;
      }

      td {
        padding: 6px 12px 7px 12px;
        cursor: default;
      }
    }

    .code-capsules {
      margin: 10px;
      border: 1px solid $color-grey;
      border-radius: 3px;
    }
  }
}

