@import "../../../../../scss/base/var";

.ModelTuningResults--container {

  .ModelTuningResults--headline {
    margin-bottom: 5px;
  }

  .ModelTuningResults--parameter-block {
    margin-bottom: 15px;
  }

  .ModelTuningResults--parameter-name {
    display: inline-block;
    width: 200px;
    overflow-wrap: anywhere;
  }

  .ModelTuningResults--parameter-value {
    display: inline-block;
    width: 50px;
    text-align: right;
  }

  .AlgorithmTuningResults--container {
    margin-bottom: 15px;
  }
}
