.optContactPolicies {
  padding: 26px 20px;
  height: 100%;
  .headlineContainer {
    display: flex;
    margin-bottom: 5px;

  .headline {
    font-size: 15px;
    font-weight: 400;
    cursor: default;
    margin-bottom: 11px;
    display: block;
  }
  .button {
    margin-left: auto;

    &.invisible {
      visibility: hidden; // hide the button instead of removing it to prevent jumping of the elements
    }
  }
  }
}
