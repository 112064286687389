@import "../../../scss/base/var.module.scss";

.augurDetailsParent {
    display: flex;
    height: 100%;
}

.augurDetailsContentParent {
    flex-grow: 1;
    overflow-x: hidden;
    position: relative;
}

.augurBiographyParent {
    min-width: 450px;
    background-color: $color-white;
    border-left: 1px solid #ddd;
    position: relative;
}
