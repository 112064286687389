.runMassPredictionModal {
  position: relative;
  width: 400px;
  min-height: 200px;

  .filter {
    margin-bottom: 20px;
  }

  .tables {
    .tableRow {
      margin: 5px 0;
    }
  }
}
