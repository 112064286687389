@import '../../../../scss/base/var';

.TabLineBubbles.design-bubbles {
  display: flex;

  .TabLineBubbles--buttons {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px; // To compensate the margin-bottom: 10px of the buttons in the bottom row

    .TabLineBubbles--button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding: 11px 20px;
      height: 36px;
      border-radius: 20px;
      margin-right: 10px;
      margin-bottom: 10px; // Only relevant when the buttons are wrapped into several lines
      transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;

      &.active {
        color: $color-white;
        background-color: $color-primary;
        .TabLineBubbles--icon {
          color: $color-white;
        }
      }

      &.inactive {
        color: $color-text;
        background-color: $color-light-grey;
        .TabLineBubbles--icon {
          color: $color-text;
        }

        &:hover {
          color: $color-white;
          background-color: $color-primary;
          .TabLineBubbles--icon {
            color: $color-white;
          }
        }
      }

      .TabLineBubbles--icon {
        margin-right: 6px;
      }
      .TabLineBubbles--text {
        display: flex;
        justify-content: center;
      }
    }
  }

  .TabLineBubbles--right {
    display: flex;
    &.center-y {
      align-items: center;
    }
    &.align-right {
      margin-left: auto;
    }
  }
}

.TabLineBubbles.design-tabs {
  display: flex;

  .TabLineBubbles--buttons {
    display: flex;

    .TabLineBubbles--button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      padding: 11px 20px;
      height: 41px;
      border-radius: 8px 8px 0 0;
      margin-top: 5px;
      margin-right: 5px;
      transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;

      &.active {
        color: $color-white;
        background-color: $color-primary;
        .TabLineBubbles--icon {
          color: $color-white;
        }
      }

      &.inactive {
        color: $color-text;
        background-color: $color-light-grey;
        .TabLineBubbles--icon {
          color: $color-text;
        }

        &:hover {
          color: $color-white;
          background-color: $color-primary;
          .TabLineBubbles--icon {
            color: $color-white;
          }
        }
      }

      .TabLineBubbles--icon {
        margin-right: 6px;
      }
      .TabLineBubbles--text {
        display: flex;
        justify-content: center;
      }
    }
  }

  .TabLineBubbles--right {
    display: flex;
    &.center-y {
      align-items: center;
    }
    &.align-right {
      margin-left: auto;
    }
  }
}
