@import '../../../../../scss/base/var';

.BarChart {
  height: 100%;
  display: flex;
  flex-direction: column;

  .BarChart--title {
    color: $color-text;
    font-weight: 400;
    margin: 0 0 13px 0;
    font-size: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .BarChart--container {
    padding: 5px;
    height: 1px;
    flex-grow: 1;

    &.BarChart--with-border {
      border: 1px solid $color-grey;
      border-radius: 4px;
    }

    &.BarChart--with-title {

    }
  }

  .BarChart--link {
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .BarChart--empty {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
  }

}
