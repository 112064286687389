@import '../../../../../../scss/base/var';

.moduleType {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  // Hopefully the negative margin-bottom doesn't cause trouble - the idea is to bring the following WizardStep closer to
  //   this WizardStep. Since this is the only WizardStep that needs this exception in the vertical margin to look good
  //   it's defined here
  margin-bottom: -20px;
}

.moduleCard {
  width: 180px;
  height: 120px;
  border: 1px solid $color-grey;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: border, color .1s ease-in-out;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    border: 1px solid $color-primary-highlight;
  }

  &.isSelected {
    border: 1px solid $color-primary-highlight;
  }

  &.explicitlyNotSelected {
    .iconContainer {
      filter: saturate(0) brightness(1.1);
    }

    .nameContainer {
      .name {
        color: $color-text-light;
      }
    }
  }

  .iconContainer {
    margin: 14px 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .defaultIcon {
      color: $color-primary;
    }
  }

  .nameContainer {
    line-height: 14px;
    height: 28px;
    display: flex;
    align-items: center;

    .name {
      color: $color-text;
    }
  }

  &.moduleCardLoading {

    .iconContainer {
      .iconCircle {
        width: 36px;
        height: 36px;
        border-radius: 18px;
      }
    }

    .nameContainer {
      display: flex;
      flex-direction: column;

      .nameBar1 {
        width: 140px;
        height: 12px;
        margin-bottom: 2px;
      }

      .nameBar2 {
        width: 100px;
        height: 12px;
        margin-top: 2px;
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  30% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.loading {
  background: linear-gradient(-45deg, $color-grey, $color-grey, lighten($color-grey, 5%), $color-grey,);
  background-size: 400% 400%;
  animation: gradient 4s ease-in-out infinite;
}
