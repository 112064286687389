@import "../../../../../../../scss/base/var";

.parent {
  // TODO still broken sometimes (influencers) because of flexbox shenanigans
  height: 100%
}

.banner {
  height: 26px;
  width: 250px;
  margin-left: auto;
  border-bottom-left-radius: 26px;
  background-color: $color-primary;
  color: white;
  font-weight: 400;
  padding: 5px 10px 5px 20px;
}