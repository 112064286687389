@import '../../../../../../scss/base/var';

.columnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-text-light;
}

.columnConstraintLevel {
  .constraintReferenceName {
    display: block;
    margin-bottom: 5px;
  }

  .speakingLevel {
    color: $color-text-light;
  }
}

.descriptionField {
  grid-column: span 2 !important;
}

.constraintTypeField {
  grid-column: 1;
}

.erroneous {
  color: $color-red !important;
}

.erroneousRow {
  td {
    background-color: $color-red-light;
  }
}

// --- Responsiveness
@media(max-width: $media-lg) {
  .descriptionField {
    grid-column: span 1 !important;
  }
}
