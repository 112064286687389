.augurDetailsSettings {
  height: 100%;
  overflow-y: scroll;
  padding: 20px;

  .settingsButtons {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}
