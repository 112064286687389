@import '../../../../../../../scss/base/var';

.tablePreview {
  margin-top: 40px;
  width: 100%;
  border: 1px solid $color-grey;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  overflow-y: scroll;

  .tableParent.limitHeight {
    height: 300px;
  }
}
