@import "../../../../../scss/base/var";

.PipelineTuningResultsBestModel {

  .PipelineTuningResultsBestModel--infos {
    margin-bottom: 10px;
    font-size: 15px;
    color: $color-text;

    .PipelineTuningResultsBestModel--info-line {
      margin-bottom: 5px;

      .PipelineTuningResultsBestModel--infos-key {
        font-weight: 400;
        margin-right: 5px;
      }
      .PipelineTuningResultsBestModel--infos-value {

      }
    }
  }

  .PipelineTuningResultsBestModel--model-container {
    border: 1px solid $color-grey;
    border-radius: 4px;

    .PipelineTuningResults--best-model-chart {
      border-bottom: 1px solid $color-grey;
    }
  }
}
