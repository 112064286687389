@import "../../../../../scss/base/var";
@import "../../../../../scss/vendor/foundation/foundation.scss";

.SearchBar {
  position: relative;
  display: inline-block;
  height: 48px;
  vertical-align: top;
  border-right: 1px solid rgba($color-white, .3);
  padding: 0 0 0 16px;

  &:hover {
    .SearchBar--input-group-button {
      .SearchBar--button {
        opacity: 1;
        .icon-search {
          color: $color-white;
        }
      }
    }
  }

  @media (max-width: 780px) {
    display: none;
  }
  @include breakpoint(small down) {
    display: none;
  }

  .SearchBar--input-group {
    margin: 0;
    border-radius: 3px;
    width: 210px;
    box-sizing: border-box;
    flex: 1 1;
    box-shadow: none;
    font-size: 15px;
    display: flex;

    @include breakpoint(medium down) {
      width: 323px;
    }
  }
  .SearchBar--input-group-field {
    color: $color-white;
    padding: 0;
    height: 48px;
    border: none;
    background: none;
    min-width: 0;

    @include placeholder {
      color: $color-white;
    }

    &:active, &:focus {
      border: none;
      background: none;
    }

    &::-ms-clear {
      display: none;
    }
  }
  .SearchBar--input-group-button {
    vertical-align: middle;

    .SearchBar--button {
      height: 48px;
      text-align: center;
      padding: 0 0 2px 0;
      cursor: pointer;
      width: 34px;
      margin: 0 4px 0 0;
      background: none !important;
      opacity: .8;
      box-shadow: none;

      .icon-search {
        font-size: 19px;
        line-height: 26px;
        color: $color-white;
        margin: 2px 3px -2px 0;
        opacity: 1;
        display: block;
      }

      .SearchBar--icon-cross {
        margin: 2px 0 -2px 3px;
        opacity: 1;
      }
    }
  }
}
