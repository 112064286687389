@import '../../../../scss/base/var';

.WithSidemenu {
  height: 100%;
  // background-color: deeppink !important;

  > div { // This div comes from react-tabs-redux <Tabs>. Unfortunately, there is no way to give this div a className
    display: flex;
    height: 100%;
    width: 100%;
  }
}

.WithSidemenu--content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden; // Hopefully this doesn't cause problems. But it's required since otherwise the Job Details logs are overlapping
}

.SideMenu {
  position: relative;
  background-color: #edf2f7;
  display: flex;
  flex-direction: column;
  min-width: 228px;
  width: 228px;
  border-right: 1px solid #ddd;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  overflow-y: auto;

  .orchestration-tab-back-icon {
    font-size: 13px;

    .menus--link {
      padding: 16px 14px 16px 18px;
    }

    .menus--text {
      display: inline-block;
    }
  }

  .SideMenu-tab-link {

    // transition: background-color ease-in-out 100ms;

    transition: color ease-in-out 100ms;

    &:focus {
      outline: none;
    }

    &.tab-link-active {
      font-weight: bold;

      > a {
        color: $color-white;
      }
    }

    &.border-top {
      border-top: 1px solid $color-grey;
    }

    &:first-child {
      border-top-left-radius: 4px;
    }

    .SideMenu-tab-icon {
      margin: 0 10px;
      transition: color ease-in-out 100ms;

      span {
        font-size: 17px; // If the icon is based on a font
        transition: color ease-in-out 100ms;
      }
    }

    > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $color-text;
      font-size: 12px;
      padding: 17px 12px;
    }

    // --- BLACK ENTRY
    &.SideMenu-tab-link--color-black {
      > a {
        color: $color-text;
      }

      .SideMenu-tab-icon {
        > a {
          color: $color-text;
        }
        fill: $color-text;
      }

      &:hover {
        > a {
          color: $color-primary;
        }

        .SideMenu-tab-icon {
          > a {
            color: $color-primary;
          }

          span { // for icons that are based on a font
            color: $color-primary;
          }
        }
      }

      &.tab-link-active {
        background: $color-primary;

        > a {
          color: white;
        }

        .SideMenu-tab-icon {
          > a {
            color: white;
          }

          // TODO where is this needed? instead of just the span below
          span { // for icons that are based on a font
            > a {
              color: white;
            }
          }
          span { // for icons that are based on a font
            color: white;
          }
        }
      }
    }

    // --- BLUE ENTRY
    &.SideMenu-tab-link--color-blue {
      > a {
        color: $color-primary-highlight;
      }

      .SideMenu-tab-icon {
        > a {
          color: $color-primary-highlight;
        }

        span { // for icons that are based on a font
          > a {
            color: $color-primary-highlight;
          }
        }
      }

      &:hover {
        > a {
          color: $color-primary;
        }

        .SideMenu-tab-icon {
          > a {
            color: $color-primary;
          }

          span { // for icons that are based on a font
            > a {
              color: $color-primary;
            }
          }
        }
      }

      &.tab-link-active {
        // At the moment, the blue links are not supposed to be "active" since they're only the "Back" buttons. So
        //   there is no need for "active" styling (yet).
      }
    }

    // ---
  }

  .SideMenu-hr {
    border-bottom: 1px solid $color-grey;
  }

  .SideMenu-headline {
    padding: 10px 10px 10px 50px;
    color: $color-text-light;
    font-weight: 500;
    cursor: default;
  }
}
