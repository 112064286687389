@import "../../../../scss/base/var";

.tooltip {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  color: $color-text;
  border-radius: 5px;
  pointer-events: none;
  opacity: 1;
  z-index: 10;
  border: 1px solid $color-grey;

  // border: 1px solid deeppink;
  // box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.arrow {
  display: none;
}
