.optCustomerTable {
  padding: 26px 20px;
  height: 100%;

  .headline {
    font-size: 15px;
    font-weight: 400;
    cursor: default;
    margin-bottom: 11px;
    display: block;
  }

  .tablePreview {
    margin-top: 20px;
  }
}
