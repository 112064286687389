@import '../../../../scss/base/var';

.ShapChart {
  .ShapChart--legend {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ShapChart--legend-gradient{
      height: 20px;
      width: 200px;
      margin: 0 20px;
    }

    .ShapChart--legend-label {
      width: 10px;
      flex-grow: 1;

      &.ShapChart--legend-label-left {
        text-align: right;
      }
    }
  }
}
