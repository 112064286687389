@import '../../../../scss/base/var';

.Button {
  min-width: 120px;
  height: 36px;
  border: none;
  border-radius: 20px !important;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.25s ease-in-out;
  padding: 0 20px;

  span {
    display: block;
    white-space: nowrap;
  }

  .Button--busy {
    display: flex;
    align-items: center;
  }

  .Button--icon {
    padding-top: 1px; // Looks better aligned to the icon text (the span is less high than the icon?)
    margin-right: 5px;
  }

  &:disabled {
    cursor: default;
    opacity: 0.25;
  }

  &:focus {
    outline: none;
  }

  &.Button--primary {
    color: $color-white;
    background-color: $color-primary;

    &:hover {
      &:enabled {
        &:not(.Button--isbusy) {
          background-color: $color-primary-lighter30;
        }
      }
    }
  }

  &.Button--secondary {
    color: $color-white;
    background-color: $color-secondary;

    &:hover {
      &:enabled {
        &:not(.Button--isbusy) {
          background-color: $color-secondary-darker30;
        }
      }
    }
  }

  &.Button--blue {
    color: $color-white;
    background-color: $color-blue;

    &:hover {
      &:enabled {
        &:not(.Button--isbusy) {
          background-color: lighten($color-blue, 20%);
        }
      }
    }
  }

  &.Button--green {
    color: $color-white;
    background-color: $color-green;

    &:hover {
      &:enabled {
        &:not(.Button--isbusy) {
          background-color: darken($color-green, 20%);
        }
      }
    }
  }

  &.Button--orange {
    color: $color-white;
    background-color: $color-orange;

    &:hover {
      &:enabled {
        &:not(.Button--isbusy) {
          background-color: darken($color-orange, 20%);
        }
      }
    }
  }

  &.Button--red {
    color: $color-white;
    background-color: $color-red;

    &:hover {
      &:enabled {
        &:not(.Button--isbusy) {
          background-color: darken($color-red, 20%);
        }
      }
    }
  }

  &.Button--white {
    background-color: $color-white;
    color: $color-primary;

    &:hover {
      &:enabled {
        &:not(.Button--isbusy) {
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }

  &.Button--transparent {
    background: none;
    color: $color-primary;
    text-decoration: none;
    box-shadow: none;
    &:hover, &:focus {
      color: $color-white;
      background: $color-primary;
      svg {
        stroke: $color-white;
      }
    }
  }
}
