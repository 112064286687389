@import '../../../../scss/base/var';

.relearningCondition {

  .fieldsContainer {
    display: flex;
    align-items: center;

    .fieldKpi {
      width: 400px;
    }

    .comparator {
      color: $color-text-light;
      margin: 0 10px;
    }

    .fieldThreshold {
      width: 100px;
    }

  }

  .infoBox {
    margin-top: 40px;
  }

  .errorContainer {

  }
}
