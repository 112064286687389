@import '../../../../../scss/base/var';

.resourcePermissions {

  .backButtonParent {
    margin: 5px 0 10px 0;
  }

  .resourceDetails {

    .detailsHeadline {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 10px;
      display: block;
    }

    .detailsList {
      margin-bottom: 10px;

      .detailsRow {
        .detailsKey {
          color: $color-text-light;
          margin-right: 5px;
        }

        .detailsValue {

        }
      }
    }
  }

  .tabLineParent {
    margin: 30px 0 20px 0;
  }

  .tabContentParent {

  }

  .userPermissions,
  .groupPermissions {
    .permissionsSubHeadline {
      display: block;
      margin-bottom: 5px;
    }

    .permissionsDescription {
      display: block;
      margin-bottom: 5px;
      font-style: italic;
    }

    .permissionsRow {
      max-height: 40px;
      display: flex;
      align-items: center;
    }
  }
}
