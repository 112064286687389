@import '../../../../../scss/base/var';

.reloginRequired {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .innerContainer {
    width: 800px;
    background-color: white;
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
  }

  .headline {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 400;
  }

  .buttonsParent {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
    }
  }
}
