@import '../../../../../scss/base/var';

$select-input-border-radius:       4px !default;
$select-input-border-width:        1px !default;
$select-input-height:              36px !default;
$select-input-internal-height:     ($select-input-height - ($select-input-border-width * 2)) !default;
$select-padding-horizontal:        10px !default;
$select-input-bg:                  #fff !default;
$select-text-color:                #333 !default;
$select-input-border-color:        #ccc !default;

.HierarchyCheckboxesInput {

  .HierarchyCheckboxesInput_header {
    display: flex;
    .HierarchyCheckboxesInput_label {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      padding: 0 0 6px 0;
      white-space: nowrap;
      cursor: default;
    }

    .HierarchyCheckboxesInput_error {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }

  .HierarchyCheckboxes_input {
    .HierarchyCheckboxes_targets_menu {
      // background-color: lightskyblue; // debug only
      margin-top: 8px;
      overflow-y: auto;
      max-height: 300px;
    }

    .HierarchyCheckboxes_targets_container {
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;
      // Vertical Line from every container (ul)
      &:before {
        position: absolute;
        top: 0.8px;
        height: 100%;
        border: 0.7px solid #ccc;
        margin-left: 10px;
      }


      // Every first .targets-container (ul)
      & &:first-of-type:after {

        position: absolute;
        width: 28px;
        height: 20px;
        left: -10px;
        top: -10px;
      }

      // Remove Vertical Line from last container (ul)
      &:last-child:before {
        display: none;
      }

 /*     .Checkbox {
        display: inline-block; // allow expand/collapse buttons to be on the same line
        position: relative;
        padding-left: 20px;
        margin-bottom: 12px;
        font-size: 14px;
        cursor: pointer;
      }*/
      .Checkbox {
        display: flex;
        position: relative;
        cursor: pointer;
        width: 20px;
        height: 20px;
        line-height: 20px;
        .Checkbox_mark_input {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
        }

        .Checkbox_mark_input:checked ~ .Checkbox_mark:after {
          display: block;
        }

        .Checkbox_mark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #fff;
          border: 1px solid #eee;
          border-radius: 4px;

          &:hover {
            background-color: #ccc;
          }

          &:after {
            content: '';
            position: absolute;
            display: none;
            left: 6px;
            top: 0;
            width: 8px;
            height: 14px;
            border: solid darkblue;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        .Checkbox_chevron {
          padding-left: 25px;
          color: darkgray;
        }
        .Checkbox_chevron + .Checkbox_label {
          padding-left: 5px;
        }
        .Checkbox_label {
          white-space: nowrap;
          padding-left: 30px;
        }
      }


      .HierarchyCheckboxes_expand_toggle {
        padding-top: 5px;
        display: inline-block;
        color: darkgray;
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }

  .HierarchyCheckboxes_autocomplete_input {
    // inherits `display: inline-block` from "react-input-autosize"
    height: $select-input-internal-height;
    padding-left: $select-padding-horizontal;
    padding-right: $select-padding-horizontal;
    vertical-align: middle;

    background-color: $select-input-bg;
    border-color: lighten($select-input-border-color, 5%) $select-input-border-color darken($select-input-border-color, 10%);
    border-radius: $select-input-border-radius;
    border: $select-input-border-width solid $select-input-border-color;
    color: $select-text-color;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;

    > input {
      background: none transparent;
      border: 0 none;
      box-shadow: none;
      cursor: default;
      display: inline-block;
      font-family: inherit;
      font-size: inherit;
      // height: $select-input-internal-height;
      margin: 0;
      outline: none;
      // padding: 0;
      line-height: 14px; /* For IE 8 compatibility */
      padding: (($select-input-internal-height - 14) * 0.5 - 2) 0 (($select-input-internal-height - 14) * 0.5 + 2); /* For IE 8 compatibility */
      -webkit-appearance: none;

      .is-focused & {
        cursor: text;
      }
    }

    &:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }

  }
}
