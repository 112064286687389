@import '../../../../scss/base/var';

.IconButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.squared {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  color: $color-text;
  margin: 5px;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out; // Faster transition looks better for the small icon
  cursor: pointer;

  &:hover{
    background-color: $color-grey;
    color: $color-white;
  }

  &.disabled{
    color: $color-grey;
    &:hover{
      background-color: $color-white;
      color: $color-grey;
    }
  }
}

.rounded {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: none;
  border: none;
  border-radius: 20px !important;
  transition: background-color 0.25s ease-in-out;

  &:disabled {
    cursor: default;
    opacity: 0.25;
  }

  &:hover, &:focus {
    color: $color-white;
    background: $color-primary;
    svg {
      stroke: $color-white;
    }
  }
}