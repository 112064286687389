[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dashboardNG' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-settings:before {
  content: "\e900";
}
.icon-refresh:before {
  content: "\e901";
}
.icon-person:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-ok:before {
  content: "\e904";
}
.icon-cross-circle:before {
  content: "\e905";
}
.icon-chevron-right:before {
  content: "\e906";
}
.icon-chevron-left:before {
  content: "\e907";
}
.icon-chevron-up:before {
  content: "\e908";
}
.icon-chevron-down:before {
  content: "\e909";
}
.icon-calculate:before {
  content: "\e910";
}
.icon-bars-chart:before {
  content: "\e90a";
}
.icon-performance-drift:before {
  content: "\e90b";
}
.icon-accuracy:before {
  content: "\e90c";
}
.icon-influencers:before {
  content: "\e90d";
}
.icon-model-viewer:before {
  content: "\e90e";
}
.icon-shedule:before {
  content: "\e90f";
}
.icon-plus:before {
  content: "\e911";
}
.icon-alert:before {
  content: "\e912";
}
.icon-reorder:before {
  content: "\e913";
}
.icon-th:before {
  content: "\e914";
}
.icon-archive:before {
  content: "\e915";
}
.icon-home:before {
  content: "\e916";
}
.icon-cross:before {
  content: "\e917";
}
.icon-bubbles-chart:before {
  content: "\e918";
}
.icon-download:before {
  content: "\e919";
}
.icon-pause:before {
  content: "\e91a";
}
.icon-reload:before {
  content: "\e91b";
}
.icon-resume:before {
  content: "\e91c";
}
.icon-play:before {
  content: "\e91d";
}
.icon-upload:before {
  content: "\e91e";
}
.icon-upload-cloud:before {
  content: "\e91f";
}
.icon-pencil:before {
  content: "\e920";
}
.icon-blank-file:before {
  content: "\e921";
}
.icon-rejected-features:before {
  content: "\e922";
}
.icon-candidate-conditions:before {
  content: "\e923";
}
.icon-datapool:before {
  content: "\e924";
}
.icon-taxonomy:before {
  content: "\e930";
}
.icon-augur:before {
  content: "\e931";
}
.icon-model-history:before {
  content: "\e932";
}
.icon-statistics:before {
  content: "\e90a";
}
.icon-archetypes:before {
  content: "\e933";
}
.icon-machine:before {
  content: "\e934";
}
.icon-tables:before {
  content: "\e90f";
}
.icon-code-capsule:before {
  content: "\e935";
}
