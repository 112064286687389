@import '../../../../scss/base/var';

.subheader {
  padding: 0;
  //min-height: 61px;
  position: fixed;
  left: 0;
  top: 48px;
  width: 100%;
  background: $color-white;
  border-bottom: 1px solid #ddd;
  z-index: 998;
  margin: 0;

  h1, .h1, h2, .h2 {
    font-size: 18px;
    padding: 5px 0 0 0;
  }

  .subheaderRow {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    height: 61px;

    &.subheaderOverlayRow {
      position: absolute;
      top: 0;
      background-color: $color-white;
      width: 100%;
    }

    .subheaderRowInner {
      position: relative;
      display: flex;
      width: 100%;
      padding: 0 .9375rem;
      height: 100%;

      .subheaderLeft {
        width: 0;
        flex-grow: 1;
        height: 100%;

        h2 {
          margin: 11px 0 8px 0;
        }
      }

      .subheaderRight {
        margin-left: auto;
        flex-grow: 0;
        flex-shrink: 0;

        display: flex;
        align-items: center;
      }

      .overlayIcon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 20px;
        left: -20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        z-index: 3;
        transition: background-color 0.1s ease-in-out;

        &:hover {
          background-color: $color-dark-grey;

          svg {
            color: $color-white !important;
          }
        }
      }

      .mainTabContainer {
        min-width: 770px; // Otherwise there will be multi-lines when the page becomes to narrow
        height: 61px;
        overflow: visible;
        position: relative;

        &.mainTabsHidden {
          .mainTabLink {
            border-bottom-color: transparent;
          }

          .mainTabUnderline {
            background-color: transparent;
          }
        }

        .mainTabLink {
          height: 63.5px;
          border-bottom: 5px solid $color-grey;
          transition: border-bottom-color 0.3s ease-in-out;

          &:focus {
            outline: none;
          }

          &.mainTabLinkActive {
            font-weight: inherit !important;

            .mainTabTitle,
            .mainTabIcon {
              color: $color-text;
            }
          }

          .mainTabText {
            margin: 0 15px;
            color: $color-text-light;
            font-size: 14px;
            font-weight: 400;
          }

          .mainTabIcon {
            margin: 0 15px;
            display: none;
            color: $color-text-light;
          }
        }

        .mainTabUnderline {
          position: absolute;
          top: 59px;
          left: 0;
          height: 5px;
          width: 0;
          border: none;
          padding: 0;
          margin: 0;
          background-color: $color-primary;
          transition: background-color 0.3s ease-in-out;
        }
      }
    }
  }

  .subheaderSimpleHeadlineContainer {
    height: 100%;
    display: flex;
    align-items: center;

    .subheaderSimpleHeadline {
      cursor: default;
      font-size: 18px;
      line-height: 1.8;
      color: $color-text;
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 400;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  30% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.loading {
  background: linear-gradient(-45deg, $color-grey, $color-grey, lighten($color-grey, 5%), $color-grey,);
  background-size: 400% 400%;
  animation: gradient 4s ease-in-out infinite;
}

// ---- TO BE REFACTORED
.detailsBar {
  height: 100%;
  padding: 11px 0;

  display: grid;
  grid-template-columns: auto auto 1fr auto auto auto;
  grid-row-gap: 0;
  grid-column-gap: 0;
  align-items: center;
  cursor: default;
}

.nameAndIcon {
  display: flex;
  align-items: center;

  .iconContainer {

  }

  .nameContainer {
    margin: 0 10px;
    font-weight: 400;
    min-width: 120px;
    max-width: 160px;

    .name {
      font-size: 18px;
      line-height: 1.3;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.loading {
        margin: 2px 0;
        height: calc(1em - 2px);
        width: 100px;
        // border-radius: calc((1em - 2px) / 2);
      }
    }

    .subName {
      font-size: 10px;
      margin-bottom: 5px;

      &.loading {
        margin: 2px 0;
        height: calc(1em - 2px);
        width: 40px;
        // border-radius: calc((1em - 2px) / 2);
      }
    }
  }

  .healthBar {
    width: 3px;
    height: 40px;
    border-radius: 2px;
    margin-right: -1px; // this is simply a trick to hide the grey left border of the following elements behind the health bar
    z-index: 2;

    &.health0 {
      background-color: $color-red;
    }

    &.health1 {
      background-color: darken($color-orange, 20%);
    }

    &.health2 {
      background-color: darken($color-yellow, 20%);
    }

    &.health3 {
      background-color: darken($color-lime, 20%);
    }

    &.health4 {
      background-color: darken($color-green, 20%);
    }

    &.healthNone {
      background-color: $color-grey;
    }
  }
}

.actionButton {
  border-left: 1px solid $color-grey;
  height: 100%;
  padding: 0 10px 0 22px;
  display: flex;
  align-items: center;


  &:hover {
    .actionButtonText {
      text-decoration: underline;
      color: $color-primary;
    }
  }

  &.disabled {
    .actionButtonLink {
      color: $color-dark-grey;
    }

    .actionButtonIcon {
      color: $color-dark-grey;
    }

    &:hover {
      .actionButtonText {
        text-decoration: none;
        color: $color-dark-grey;
      }
    }
  }

  .actionButtonLink {
    text-decoration: none;
    white-space: nowrap;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-decoration-color: $color-primary;
  }

  .actionButtonIcon {
    color: $color-primary;
    font-size: 17px;
    margin-right: 5px;
    width: 17px;
    height: 17px;
  }

  .actionButtonText {
    &.loading {
      background-color: $color-grey;
      color: transparent !important;
      // border-radius: 7px;
    }
  }
  &.hidden {
    padding: unset;
  }
  &.actionButtonError {
    .actionButtonText {
      color: $color-red;
    }

    .actionButtonLink {
      text-decoration-color: $color-red;
    }

    .actionButtonIcon {
      color: $color-red;
    }
  }
}

.detailValue {
  border-left: 1px solid $color-grey;
  height: 100%;
  padding: 0 10px 0 22px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;

  .detailValueKey {
    color: $color-text-light;
    margin-right: 5px;
    min-width: 37px;
  }

  .detailValueValue {
    &.loading {
      width: 120px;
      height: 1em;
    }
  }
}

.errorModal {
  cursor: default;
  color: $color-red !important;
}

// --- Responsiveness
@media (max-width: 1024px) {
  .subheaderRight {
    display: none !important;
  }
}

@media (max-width: 800px) {
  .mainTabText {
    display: none !important;
  }

  .mainTabIcon {
    display: block !important;
  }
}
