@import '../../../../../scss/base/var';

.TextInputLinePassword {

  .TextInputLinePasswordHeader {
display: flex;
    flex-direction: row;
    .TextInputLinePasswordLabel {
      color: $color-text-light;
      font-size: 14px;
      padding: 0 0 6px 0;
      white-space: nowrap;
      cursor: default;
      flex-shrink: 1;
      flex-grow: 1;
      width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .TextInputLinePasswordError {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }
  .inputContainerError{
    border: 1px solid $color-red !important;
    border-radius: 3px;
    display: flex;


  }


  .TextInputLinePasswordInputGroup {
    display: flex;
    border: 1px solid #bbb;
    border-radius: 3px;
    input{
      font-size: 15px;
      padding: 13px 18px;
      line-height: 1.3;
      height: 46px;
      margin: 0;
      width: 100%;
      border: none;
      outline: none;
    }
    &.TextInputLinePasswordDisabled {
      .TextInputLinePasswordRevealButtonParent {
        .TextInputLinePasswordRevealButton {
          background-color: #e6e6e6 !important;
        }
      }
    }


    .TextInputLinePasswordRevealButtonParent {
      flex: 0 1 auto;
      display: table-cell;

      .TextInputLinePasswordRevealButton {
        height: 46px;
        padding: 0 0 2px 0;
        cursor: pointer;
        width: 50px;
        margin: 0 0 0 -1px;
        display: flex;
        align-items: center;
        justify-content: center;

        outline: none;

        &:hover {
          .revealIcon {
            opacity: 1;
          }
        }

        .revealIcon {
          opacity: 0.8;
          transition: opacity 0.25s ease-out;
          color: $color-primary;
        }
      }
    }
  }
}
