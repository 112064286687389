@import '../../../../../../../../scss/base/var';

$parent-margin-x: 20px;

.scoreDistributionCompare {
  padding: 26px $parent-margin-x;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .scoreDistributionHeader {
    position: absolute; // to get some more space for the tooltip
    z-index: 2;
    width: calc(100% - 2 * #{$parent-margin-x});

    .scoreDistributionHeadline {
      font-weight: 400;
    }

    .scoreDistributionTabs {
      margin-top: 20px;
    }
  }

  .scoreDistributionNoReportCodes {
    padding-top: 140px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .scoreDistributionCompareLoading {
    position: relative;
    flex-grow: 1;
  }

  .scoreDistributionCompareError {
    padding-top: 140px;
  }

  .scoreDistributionCompareLoaded {
    width: 100%;
    height: 100%;

    .compareHeatmap {
      // margin-top: 20px;
      overflow-y: auto;
    }


    .tooltip {
      display: flex;
      flex-direction: column;
      z-index: 3;

      .tooltipLine {
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        .tooltipKey {
          font-weight: 400;
          margin-right: 5px;
        }

        .tooltipValue {}

        .tooltipAlertIcon {
          margin-left: 5px;
          color: $color-orange-dark;
        }
      }
    }

    .compareToolTip {
      background-color: white;
      border: 1px solid #dce2eb;
      border-radius: 4px;
      padding: 10px 10px 0 10px;
    }

    .compareBarChart {
      padding-top: 140px;
    }
  }
}