@use "../../../../../../../scss/base/theme.module";
@import '../../../../../../../scss/base/var';

.columnOption {
  background-color: $color-white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  align-items: center;

  &.isSelected {
    background: theme.$color-secondary-lighter90;

    &:after {
      content: "\e904";
      font-family: 'dashboardNG';
      position: absolute;
      font-size: 18px;
      right: 10px;
      line-height: 23px;
      color: theme.$color-secondary;
    }
  }

  &.isDisabled {
    background-color: $color-light-grey;
    cursor: default;

    .colName,
    .colType {
      color: $color-text-light;
    }
  }

  &:hover {
    background-color: $color-light-grey;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-grey;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .colType {
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-text-light;
  }

  .colName {
    width: 0;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-text;
  }
}
