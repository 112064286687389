@import "../../../../../scss/base/var";

.PipelineTuningResultsSingleResults {

  .SingleResultsPerPipeline {

    .SingleResultsPerPath {
      margin-bottom: 30px;

      .SingleResultsPerPath--headline {
        display: flex;
        flex-wrap: wrap;

        .SingleResultsPerPath--headline-item {
          flex-shrink: 0;
          white-space: nowrap;
          cursor: pointer;

          &-toggle {
            width: 30px;
          }

          &-best-score {
            width: 150px;
          }

          &-classifier {
            width: 280px;
          }

          &-parameter-settings {
            width: 150px;
          }

          .SingleResultsPerPath--headline-key {
            font-weight: 400;
            margin-right: 5px;
          }
        }
      }

      .SingleResultsPerPath--body {
        margin-top: 10px;
        margin-left: 30px; // To compensate for the expand / collapse icon

        .SingleResultsPerPath--pipeline {
          margin-bottom: 10px;
          border: 1px solid $color-grey;
          border-radius: 4px;
        }

        .SingleResultsPerPath--results-table {
          table {

            th, td {
              white-space: nowrap;
            }

            thead {
              .tr--header-node {
                th {
                  border-bottom: none;
                  color: $color-text;
                  padding: 8px 10px 2px 10px;
                }
              }
              .tr--header-parameter{
                th {
                  border-top: none;
                  padding: 2px 10px 10px 10px;
                }
              }
            }
          }
        }
      }
    }

  }

}
