$active: #9b9b9b;
$default: #e5d6f1;
$success: $color-primary-highlight;
$danger: #d7d7d7;

$node-color: #b7d9fd;
$node-positive-color: $color-primary-highlight;
$node-negative-color: #d7d7d7;
$node-select-color: #000000;

.node-shape {
  fill: $node-color;

  &.tree-chart_node {
    cursor: pointer;

    &.tree-chart_node--positive {
      fill: $color-primary-highlight;
      stroke: $color-primary-highlight-darker60;
    }

    &.tree-chart_node--negative {
      fill: $node-negative-color;
      stroke: darken($node-negative-color, 20%);
    }

    &.active {
      fill: #fff;
    }

    &.selected {
      stroke-width: 2px;
      //stroke: $node-select-color;
      stroke-dasharray: 2;
    }
  }
}

.tree-chart_tooltip-container {
  position: absolute;
  top: 0;
  left: 0;

}

.tree-chart_tooltip {
  padding: .5em;
  border-radius: 5px;
  pointer-events: none;


  dl {
    @include clearfix();
    margin-bottom: .5em;
    width: 180px;
  }

  dt, dd {
    font-size: 80%;
    float: left;
    margin: .1em .1em;
  }

  dt {
    clear: both;
    text-align: left;
    width: 70%;
  }

  dt:after {
    content: ':'
  }

  &.tree-chart_tooltip--positive {
    border-color: $color-primary-highlight-lighter60;
  }

  &.tree-chart_tooltip--negative {
    border-color: $color-primary-highlight-lighter30;
  }

  .tooltip-chart {
    position: relative;
    width: 222px;

    .tooltip-chart-bar--positive {
      fill: $color-primary-highlight;
      stroke: $color-primary-highlight-darker60;
    }

    .tooltip-chart-bar--negative {
      fill: $node-negative-color;
      stroke: darken($node-negative-color, 20%);
    }

    .tooltip-chart-axis__main {
      font-weight: bold;
      tspan {
        font-weight: normal;
      }
    }
  }
}

.tree-chart {
  position: relative;
  // padding-bottom hack to scale the inline svg to the container width
  // see https://css-tricks.com/scale-svg/#article-header-id-10
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;


  svg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}


.link {
  fill: none;
  stroke: $active;
}

.link--highlighted {
  stroke: $success;
}
