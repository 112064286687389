@import '../../../../../../scss/base/var';

.dictionaryContainer {

  .header {
    display: flex;

    .label {
      color: $color-text-light;
      margin-right: auto;
      display: block;
    }

    height: 20px;
  }

  .dictionary {
    border: 1px solid $color-grey;
    border-radius: 4px;
  }
}
