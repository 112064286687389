@import '../../../../../../scss/base/var';

.modalCommitParent{
  min-width: 620px; // adjust later to be responsive
  max-width: 620px; // adjust later to be responsive
  padding-top: 24px; // Space for the X-icon

  .modalCommitElements{}


  .modalCommitButtons{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}