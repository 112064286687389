@import '../../../../../../../../../scss/base/var';

.InputDataCategory {
  .TableSelection {
    .TableSelection--field-parent {
      max-width: 600px;
    }

    .TableSelection--buttons {
      margin-top: 40px;
      padding-top: 20px;
      border-top: 1px solid $color-grey;
      display: flex;
      justify-content: flex-end;

      .Button &:not(:last-child){
        margin-right: 10px;
      }
    }
  }
}
