// MODE 1: Card view
.datapools--item{

}

// MODE 2: Table view
.datapools-wrap-table {
  padding: 0 20px 20px;

  table {
    border-radius: 3px;
    border-style: hidden;
    box-shadow: 0 0 0 1px $color-grey;
    overflow: hidden;
    font-weight: 400;
    thead {
      background: $color-light-grey;
      th, td {
        font-size: 12px;
        color: $color-text;
        font-weight: 700;
      }
    }
    tbody {
      tr {
        background: $color-white;
        &:nth-child(2n) {
          background: $color-light-grey;
        }
      }
      [class^="icon-"], [class*=" icon-"] {
        font-size: 19px;
        margin-left: 1px;
        text-decoration: none;
        color: $color-text-light;
        &:hover {
          color: $color-text;
        }
      }
      .settings--link {
        float: right;
        display: inline-block;
        .icon-settings {
          display: inline-block;
        }
      }
      td{
        vertical-align: middle;
      }
      th, td{
        padding: 5px;
      }
      .name{
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
}

.asc_desc {
  position: relative;
  display: inline-block;
  padding-right: 14px;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: transparent;
  }

  &:before {
    margin: -9px 0 0;
    border-bottom-color: $color-grey2;
  }

  &:after {
    margin: 1px 0 0;
    border-top-color: $color-grey2;
  }

  &:hover {
    &:before {
      border-bottom-color: $color-text-light !important;
    }

    &:after {
      border-top-color: $color-grey2 !important;
    }
  }

  &.asc {
    color: $color-text;

    &:before {
      border-bottom-color: $color-primary;
    }

    &:hover {
      &:before {
        border-bottom-color: $color-primary !important;
      }

      &:after {
        border-top-color: $color-text-light !important;
      }
    }
  }

  &.desc {
    color: $color-text;

    &:after {
      border-top-color: $color-primary;
    }

    &:hover {
      &:before {
        border-bottom-color: $color-text-light !important;
      }

      &:after {
        border-top-color: $color-primary !important;
      }
    }
  }
}
