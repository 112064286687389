@import '../../../../scss/base/var.scss';

.k8sResourcesSelect {
  width: 100%;
  max-width: 600px;

  .title {
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 15px;
    display: block;
    cursor: default;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    .col {
      min-width: 0; // To make the column width static, even if the content grows

      &.gpuAvailableCol {
        grid-column: span 2;
      }

      .inputParent {

      }

      .checkboxDisabled {
        color: $color-dark-grey;
      }

      .noGpuAvailableInfo {
        display: block;
        margin-bottom: 10px;
        color: $color-text;
        font-style: italic;
      }
    }
  }
}
