@import '../../../../scss/base/var';

.container {

  &.removeTopBorder {
    .content {
      border-top-width: 0;
    }
  }

  margin-bottom: 12px; // Compensate for the expand/collapse button

  .headline {
    font-size: 15px;
    font-weight: 500;
    color: $color-text-light;
    margin-bottom: 10px;
  }

  .button {
    position: absolute;
    left: 50%;
    bottom: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    z-index: 1;
    height: 24px;
    border: 1px solid #dce2eb;
    line-height: 20px;
    border-radius: 12px;
    background: #fff;
    text-decoration: none;
    font-weight: 400;
    color: $color-primary;
    margin: 0 0 0 -65px;
    width: 130px;

    &:hover {
      outline: none !important;
      background-color: $color-light-grey;
    }

    .buttonIcon {
      margin-left: 5px;
      margin-right: -5px;
    }
  }

  .content {
    display: flex;
    overflow: visible;
    transition: height 0.25s ease-out, padding 0.25s ease-out, border-top-width 0.25s ease-out; // TODO does not work without setting height etc.
    border-radius: 4px;
    position: relative;
    border: 1px solid $color-grey;

    .children {
      overflow: hidden;
      width: 100%;
    }
  }
}
