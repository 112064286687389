@import "../../../../../../scss/base/var";

.ModelParameters {
  .ModelParameter--model-step {
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    &:not(:last-child){
      border-bottom: 1px solid $color-grey;
    }

    &-active {
      &:after {
        content: "";
        position: absolute;
        width: 4px;
        border-radius: 2px;
        left: -2px;
        top: 0;
        bottom: 0;
        background-color: $color-primary;
      }
    }

    .ModelParameter--model-step--display-name {
      font-weight: 400;
      font-size: 15px;
    }

    .ModelParameter--model-step--description {
      margin-top: 5px;
    }

    .ModelParameter--model-step--parameters {
      .ModelParameter--model-step--static,
      .ModelParameter--model-step--tuning {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .ModelParameter--model-step--params-headline {
          font-weight: 400;
          color: $color-text-light;
          margin-bottom: 5px;
        }

        .ModelParameter--model-step--params-container {
          display: flex;
          flex-direction: column;

          .ModelParameter--model-step--params {
            width: 300px;
            margin-bottom: 5px;
            justify-content: stretch;

            .ModelParameter--model-step--params-name {
              font-weight: 400;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

}
