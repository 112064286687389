.constraintExhaustion {
  padding: 26px 20px;
  height: 100%;

  .headline {
    font-size: 15px;
    font-weight: 400;
    cursor: default;
    margin-bottom: 11px;
    display: block;
  }

  .summaryContainer {
    margin-bottom: 20px;

    .row {
      span {
        display: inline-block;

        &.key {
          font-weight: 400;
          margin-right: 5px;
        }
      }
    }
  }
}
