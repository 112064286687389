@import '../../../../../scss/base/var';

.noElements {
  height: 335px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;

  .noElementsContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .augurPic {
      width: 101px;
      height: 60px;
      margin: 0 auto 27px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .addButton {
      margin-top: 20px;
    }
  }
}
