@import "../../../../../scss/base/var";
@import "../../../../../scss/vendor/foundation/foundation.scss";

.SearchResult {
  color: $color-text;
  position: absolute;
  left: 0;
  top: 100%;
  background: $color-white;
  padding: 10px 20px;
  margin: 0 0 12px 0;
  border-radius: 1px;
  box-shadow: 0 1px 2px 0 #7d9dc2;
  z-index: 999;
  min-width: 150px;
  width: 100%;
  text-align: left;
  font-size: 12px;
  &:before {
    content: "";
    position: absolute;
    left: 23px;
    top: -13px;
    border-style: solid;
    border-width: 6px;
    border-color: transparent;
    border-bottom-color: #7d9dc2;
  }
  &:after {
    content: "";
    position: absolute;
    left: 23px;
    top: -12px;
    border-style: solid;
    border-width: 6px;
    border-color: transparent;
    border-bottom-color: $color-white;
  }

  .SearchResult--headline {
    color: $color-primary;
    margin: 0 -20px 15px -20px;
    padding: 3px 20px 15px 20px;
    border-bottom: 1px solid $color-light-grey;
    font-weight: 400;
    font-size: 12px;
  }

  .SearchResult--group {
    &:last-child {
      .search--list {
        padding: 0 0 2px 0;
      }
    }

    .SearchResult--label {
      color: $color_text-light;
      font-size: 13px;
      font-weight: 400;
      padding: 0 0 10px 0;
    }

    .SearchResult--list {
      padding: 0 0 9px 0;

      .SearchResult--list-li {
        padding: 0 0 8px 0;
        a {
          color: $color-text;
          text-decoration: none;
          display: block;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

  }
}
