@import "../../../../../scss/base/var";
@import "../../../../../scss/vendor/foundation/foundation.scss";

.TextInputSearch {
  position: relative;
  display: inline-block;
  height: 36px;
  padding: 0 0 0 16px;
  border-radius: 18px;
  background-color: $color-white;
  border: 1px solid $color-grey;

  .SearchBarInputGroup {
    margin: 0;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 15px;
    display: flex;

    .TextInputSearchInputGroupField {
      padding: 0;
      height: 34px;
      border: none;

      @include placeholder {
        color: $color-text-light;
      }

      &:active, &:focus {
        border: none;
        background: none;
      }

      &::-ms-clear {
        display: none;
      }
    }
  }

  .SearchBarInputGroupButton {
    vertical-align: middle;

    .SearchBarButton {
      height: 36px;
      text-align: center;
      padding: 0 0 2px 0;
      cursor: pointer;
      width: 34px;
      margin: 0 4px 0 -6px;
      box-shadow: none;
      &:hover{
        .iconSearch {
          color: $color-primary;
        }
      }

      .iconSearch {
        font-size: 19px;
        line-height: 26px;
        color: $color-text-light;
        transition: color 0.1s ease-in-out;
        margin: 0 0 -2px -2px;
        display: block;
      }

      .SearchBarIconCross {
        margin: 2px 0 -2px 3px;
        opacity: 1;
        color: $color-text-light;
        transition: color 0.1s ease-in-out;
        &:hover{
          color: $color-primary;
        }
      }
    }
  }

  .fixedContainer {
    width: 36px;
  }
}
