@import '../../../../scss/base/var';

.multiPageWizard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .headlineContainer {
    // border: 1px dashed deeppink;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 30px 20px;
    cursor: default;
    border-bottom: 1px solid $color-light-grey;
    position: relative;

    .shadowBodyTop {
      bottom: -6px;
      height: 6px;
      left: 0;
      right: 0;
      position: absolute;
      background: linear-gradient(to bottom, $color-light-grey, transparent);
    }
  }

  .bodyContainer {
    // border: 1px dashed deepskyblue;
    flex-grow: 1;
    overflow-y: scroll;
  }

  .footerContainer {
    // border: 1px dashed limegreen;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    // overflow: hidden;
    padding: 20px 60px;
    border-top: 1px solid $color-light-grey;
    position: relative;

    .shadowBodyBottom {
      top: -6px;
      height: 6px;
      left: 0;
      right: 0;
      position: absolute;
      background: linear-gradient(to bottom, transparent, $color-light-grey);
    }

    .buttonsLeft {
      // border: 1px dashed purple;
      flex-grow: 0;
      display: flex;

      .buttonPrev {
        margin-left: 0;
      }

      .buttonCancel {
        margin-left: 10px;
      }
    }

    .infoCenter {
      // border: 1px dashed lightseagreen;
      flex-grow: 1;
      flex-shrink: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      cursor: default;
      position: relative;

      .pageTitle {
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }

      .pageInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;

        .pageBubble {
          width: 16px;
          height: 16px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: border 0.1s ease-in-out, background-color 0.3s ease-in-out;
          position: relative;

          &.pageBubbleUpcoming {
            border: 1px solid $color-primary;
            background-color: $color-white;
          }

          &.pageBubbleCurrent {
            border: 1px solid $color-primary;
            background-color: $color-white;

            &::after {
              content: "";
              background-color: $color-primary;
              position: absolute;
              height: 8px;
              width: 8px;
              border-radius: 4px;
              top: 3px;
            }
          }

          &.pageBubbleDone {
            border: 1px solid $color-green;
            background-color: $color-green;

            svg {
              color: $color-white;
            }
          }
        }

        .pageBubbleConnection {
          width: 8px;
          height: 1px;
          background-color: $color-text-light;
        }
      }
    }

    .buttonsRightPlaceholder {
      width: 250px;
    }

    .buttonsRight {
      // border: 1px dashed darkorchid;
      flex-grow: 0;
      display: flex;

      .buttonNext {
        // This is done to compensate for the second button on the left to make sure the "step" text is properly centered.
        margin-left: 130px;
      }

      .buttonSubmit {
        // This is done to compensate for the second button on the left to make sure the "step" text is properly centered.
        margin-left: 130px;
      }
    }
  }
}

.wizardPage {
  // border: 1px dashed darkblue;
  padding: 20px 40px;
  margin: 0 auto;
}

.wizardStep {

  &:not(:last-child) {
    margin-bottom: 60px;
  }

  &:last-child {
    margin-bottom: 40px;
  }

  .wizardStepTop {
    // border: 1px dashed limegreen;
    display: flex;
    flex-direction: row; // This is always set to "flex-direction: column" for screens smaller than $media-sm
    margin: 0 auto;
    max-width: 1200px;

    .infoContainer {
      // border: 1px dashed deeppink;
      width: 440px;
      min-width: 300px;
      flex-grow: 0;
      flex-shrink: 1;
      margin-right: 60px;
      display: flex;
      flex-direction: row;

      .bubbleContainer {
        margin-right: 20px;
        // border: 1px dashed orange;
      }

      .textContainer {
        // border: 1px dashed #009688;
        cursor: default;

        .title {
          font-weight: 500;
          height: 40px; // Same as the bubble
          display: flex;
          align-items: center;
        }

        .description {
          display: block;
          // margin-top: 16px;
        }
      }
    }

    .inputContainer {
      // border: 1px dashed deepskyblue;
      flex-grow: 1;
      flex-shrink: 1;
      width: 1px; // To make all steps grow equally
      // overflow: hidden;

      .errorParent {
        // !! Actually no error is displayed here at the moment. The errors are treated by the input components themselves
        // height: 40px; // Same as the bubble
        // border: 1px dashed darkred;
        overflow: hidden;
        display: flex;
        align-items: center;
        cursor: default;
      }

      .inputParent {
        // border: 1px dashed lightsalmon;
      }
    }
  }

  .wizardStepBottom {
    margin: 20px auto 0 auto;
    max-width: 1200px;

    &.fullWidth {
      max-width: unset;
    }
  }
}

.error {
  color: $color-red;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  font-weight: 400;
}

// --- Responsiveness
@media(max-width: $media-md) {
  .multiPageWizard {
    .footerContainer {
      .buttonsRightPlaceholder {
        display: none;
      }

      .buttonsRight {
        .buttonNext {
          margin-left: 0;
        }

        .buttonSubmit {
          margin-left: 0;
        }
      }
    }
  }
  .wizardStep {
    .wizardStepTop {
      .infoContainer {
        width: 300px;
        margin-right: 40px;
      }
    }
  }
}

@media(max-width: $media-sm) {
  .multiPageWizard {
    .footerContainer {
      .infoCenter {
        display: none;
      }

      .buttonsRight {
        margin-left: auto;
      }
    }
  }

  .wizardStep {
    .wizardStepTop {
      flex-direction: column;

      .infoContainer {
        width: 100%;
        max-width: unset;
      }

      .inputContainer {
        width: 100%;

        .errorParent {
          height: 10px;
        }
      }
    }
  }
}

@media(max-width: $media-xs) {
  .wizardStep {
    .wizardStepTop {
      .infoContainer {
        .bubbleContainer {
          display: none;
        }
      }
    }
  }
}
