@import '../../../../../../../../../scss/base/var.module';

.PipelineTuningButtons {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid $color-grey;
  display: flex;
  justify-content: space-between;

  .Button &:not(:last-child) {
    margin-right: 10px;
  }
}

.PipelineTuningButtonsRight {
  display: flex;
}
