.ModelSettings {
  padding: 26px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ModelSettings--header {
    margin-bottom: 20px; // Maybe this is a bit too much? But it's the same as for the Influencers for example
    flex-shrink: 0;
  }

  .ModelSettings--category {
    overflow-y: auto;
    padding: 16px 20px 0;
    height: 100%;
  }
}
