@import '../../../../../scss/base/var';

.jobGroupEditor {
  height: 100%;
  display: flex;
  flex-direction: column;

  .node {
    background-color: $color-white;
    border: 1px solid $color-dark-grey;
    border-radius: 5px;
    width: 90px;
    height: 35px;
    padding: 5px;
    margin: 5px 5px 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;

    &:hover {
      background-color: $color-light-grey;
    }
  }

  .header {
    display: flex;
    align-items: center;
    border-top: 1px solid $color-grey;

    .tabs {
      width: 80%;
      overflow: auto;
      margin-right: 10px;
    }
  }

  input {
    width: 20%;
    height: 36px;
    padding: 8px 13px;
    font-size: 14px;
  }

  .categories {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 5fr 3fr 3fr;
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid $color-grey;
    border-radius: 0 0 8px 8px;
  }

  .list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $color-grey;
    padding: 10px 0;

    ul {
      overflow: auto;

      li {
        &:nth-child(2n + 1) {
          .listItem {
            background-color: $color-light-grey;
          }
        }
      }
    }
  }

  .listHeader {
    margin-bottom: 5px;
    padding: 0 10px;
  }


  .listItem {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .listItemText {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 22ch;
  }
}
