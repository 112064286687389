@import '../../../../../../scss/base/var';

.trainingTable {

  .errorContainer {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid $color-red;
    background-color: $color-red-light;
    border-radius: 4px;
    text-align: center;
    cursor: default;
  }

  .row {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.rowTrainingTable {

    }

    &.rowIdColumns {

    }

    &.rowLabel {

    }

    &.rowPosNegValue {
      display: flex;
      flex-direction: row;
      width: 100%;

      .colPosNegValue {
        width: 0;
        flex-grow: 1;

        &.colPosValue {
          margin-right: 10px;
        }

        &.colNegValue {
          margin-left: 10px;
        }
      }
    }
  }
}
