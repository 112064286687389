@import '../../../../../scss/base/var';

.errorContainer {

  .errorInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .errorMessageContainer {
      width: 600px;
      height: 260px;
      background-color: white;
      border: 1px solid $color-grey;
      border-radius: 5px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .checkingAgain {
        margin-top: 20px;
        font-weight: 400;
      }
    }
  }
}
