@import '../../../../../scss/base/var';

.jobLogs {
  padding: 10px;
  max-height: 500px;
  overflow-y: auto;
  // scroll-snap-type: y proximity;

  &.empty {
    color: $color-text-light;
  }

  .logEvent {
    display: block;
    font-family: $font-monospace;

    &:last-child {
      // scroll-snap-align: end;
    }

    .lineNumber {
      padding-right: 10px;
      border-right: 1px solid $color-grey;
    }

    .logMessage {
      padding-left: 10px;
    }
  }
}
