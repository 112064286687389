@import '../../../../scss/base/var';

.tooltip {
  pointer-events: none;
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  text-align: left;
  display: table;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    border: 5px solid transparent;
    border-top-color: $color-grey;
    bottom: -10px;
    margin: 0 0 0 -5px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    border: 5px solid transparent;
    border-top-color: $color-white;
    bottom: -9px;
    margin: 0 0 0 -5px;
  }

  .tooltipGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
}

.chartAxis {
  path {
    display: none;
  }

  .bottomTicks {
    font-size: 12px;

    line {
      display: none;
    }

    text {
      fill: #979FA8;
    }
  }
}

.performanceDrift {
  position: relative;
  text-align: center;
  padding: 19px 31px;
  height: 100%;
}

.performanceDriftChart {
  height: 500px;

  & > div {
    height: 500px;
  }
}

.performanceDriftChartSizer {
  height: 120px;

  & > div {
    min-height: 120px;
  }
}

.sliceWarning {
  position: absolute;
  left: 100px;
  white-space: nowrap;
  color: $color-text-light;
}
