.archetype {
  .originParent {
    margin-top: 16px;
  }
  .archetypeCodeParent {
    margin-top: 20px;
  }
  .archetypeVersionParent {
    margin-top: 20px;
  }
}
