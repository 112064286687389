@import "../../../../../scss/base/var";
@import "../../../../../scss/vendor/foundation/foundation.scss";

.header {
  background: $color-primary;
  color: $color-white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 48px;

  .headerRow {
    position: relative;
    width: calc(75rem - 30px);
    max-width: 98vw;
    margin: 0 auto;
    display: flex;
  }

  .headerLeft {
    padding: 4px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .headerLogo {
      display: inline-block;
      height: 32px;
      background-size: cover;
      vertical-align: top;
      margin: 4px 10px 4px 0;
    }

    .headerLabel {
      height: 30px;
      margin-left: 20px;
      background-color: $color-white; // might be overriden by inline-styles
      color: $color-text; // might be overriden by inline-styles
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      border-radius: 5px;
      font-weight: 400;
      cursor: default;
      white-space: nowrap;
    }
  }

  .headerRight {
    text-align: right;
    margin-left: auto;
    display: flex;
  }
}
