@import '../../../../scss/base/var';

.MainContainer {
  margin-top: 110px; // To properly get below the header and the tab line
  height: 100%;

  &.no-main-tabs { // Ignore the height of the main tabs (which means: put the component 48px higher)
    margin-top: 62px;
    height: calc(100vh - 62px - 20px); // 20px bottom "margin"
  }

  &.scrollable-y {
    height: calc(100vh - 110px); // Don't remove the margin at the bottom: Make the component grow to the bottom line
    overflow-y: auto;
    overflow-x: hidden;

    .MainContainer--inner {
      padding-top: 20px;
      padding-bottom: 40px;
      height: fit-content;
    }
  }

  &.fixed-y {
    height: calc(100vh - 10px); // Add an additional margin at the bottom!
    overflow-y: hidden;
    padding-top: 130px;
    padding-bottom: 10px;
    margin-top: 0;
  }

  .MainContainer--inner {
    border-radius: 4px;
    border: 1px solid #ddd;
    height: 100%;
    background-color: $color-white;
    margin-left: auto;
    margin-right: auto;
    position: relative; // to make position: absolute work to place elements inside

    &.transparent {
      border: none;
      background-color: transparent;
    }

    &.centerwidth {
      width: calc(75rem - 30px); // The -30px are needed to match the width of the center; to align it with the style of the header
      max-width: 98vw;
    }

    &.fullwidth {
      width: calc(100vw - 30px); // The -30px generate a margin on the left and right
    }
  }
}
