@import '../../../../../scss/base/var';

.FlatHistogram {
  .FlatHistogram--tooltip {
    padding: 5px;

    table {
     tr {
       font-size: 12px;
       td {
         padding: 5px;
         border: none;
         background-color: $color-white;

         &.key {
           font-weight: 400;
           margin-right: 10px;
         }
       }
     }
    }
  }
}
