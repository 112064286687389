@import '../../../../scss/base/var.scss';

.workbenchMetrics {
  border-top: 1px solid $color-grey;
  background-color: white;
  padding-top: 10px;
  position: relative; // Required for the absolute positioned button
}

.chartParent {
  height: 130px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.chartHeadline {
  cursor: default;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-weight: 500;
  }
}

.chart {
  position: relative;
  height: 100px;
}

.legend {
  display: flex;
  padding: 0 10px 10px 10px;
  justify-content: center;

  .legendItem {
    display: flex;
    margin: 0 10px;
    align-items: center;

    .legendIcon {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }

    .legendIconBackgroundVarA {
      background-color: #6AA0EB;
    }

    .legendIconBackgroundVarB {
      background-color: #b9c3cb;
    }

    .legendIconBackgroundVarC {
      background-color: #f2ad5f;
    }

    .legendLabel {
      font-weight: 400;
      cursor: default;
    }
  }
}

.metricsCollapsed {
  border-top: 1px solid $color-grey;
  background-color: white;
  height: 30px;
  padding: 5px;
  display: flex;

  .collapsedLabel {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    font-weight: 400;
  }
}

.metricsIcon {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  border: 1px solid $color-grey;
  background-color: $color-light-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  svg {
    color: $color-text-light;
  }
  &:hover {
    background-color: $color-grey;
  }
}

.metricsExpanded {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
}
