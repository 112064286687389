@import '../../../../../../../../scss/base/var';

$parent-margin-x: 20px;

.heatmap {
  // margin-top: 20px;
  overflow-y: auto;
}

.tooltip {
  display: flex;
  flex-direction: column;
  z-index: 3;

  .tooltipLine {
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    .tooltipKey {
      font-weight: 400;
      margin-right: 5px;
    }

    .tooltipValue {

    }

    .tooltipAlertIcon {
      margin-left: 5px;
      color: $color-orange-dark;
    }
  }
}

.content {
  position: relative;
  text-align: center;
  padding: 19px 31px;
  height: 100%;
}

.chart {
  height: 500px;
  & > div {
    height: 500px;
  }
}

.timeline {
  height: 120px;
  & > div {
    min-height: 120px;
  }
}
