.Influencers {
  padding: 26px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .Influencers--header {
    margin-bottom: 20px;
  }

  .Influencers--charts {
    height: 0;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .Influencers--buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    background-color: white;
    position: sticky;
    bottom: 0;
    padding: 10px 20px 0 20px;
  }
}
