

.container {
  height: 100%;
}

.content {
  margin-top: 0;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  padding: 20px 15px;
  height: 100%;
}

.topButtonBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  > * {
    margin-left: 10px;
  }
}

.listContainer {
  padding: 0;
}
