@import '../../../../../scss/base/var';

.contentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.emptyList {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.listContainer {
  margin: 10px 0 10px 0;
}

.element {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 20px;
}

.element:hover {
  background-color: $color-grey;
}

.elementIcon {
  color: $color-text;
  margin-right: 10px;
  min-width: 20px;
}

.elementName {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.shutdown {
  background-color: $color-orange;
  color: $color-white;
  height: 20px;
  border-radius: 10px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
