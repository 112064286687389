@import "../../../../../scss/base/var";

.ParameterTuningSelection--container {

  .ParameterTuning--search-strategy-select {
    width: 30%;
  }

  .ParameterTuning--headline {
    margin-bottom: 5px;
  }

  .ParameterTuningAlgorithm--container {
    margin-top: 20px;
    border-top: 1px solid $color-grey;
    padding-top: 20px;
    display: flex;

    .ParameterTuning--element {
      margin-left: 20px;
      flex-grow: 1;
    }

    .ParameterTuning--deselected {
      color: $color-dark-grey;
    }

    .ParameterTuning--parameter-container {
      display: flex;
      flex-wrap: wrap;

      .ParameterTuningParameter--container {
        width: 30%;
        margin-right: 20px;
        margin-top: 10px;

        display: flex;
        flex-direction: column;

        .ParameterTuning--input {
          margin-top: auto;
        }
      }
    }
  }
}
