@import '../../../../../../../../../scss/base/var';

.CandidateConditionsCategory {

  .CandidateConditions {
    .CandidateConditions--field-parent {
      max-width: 600px;

      .TextInputLine:not(:last-child){
        margin-bottom: 20px;
      }
    }

    .CandidateConditions--buttons {
      margin-top: 40px;
      padding-top: 20px;
      border-top: 1px solid $color-grey;
      display: flex;
      justify-content: flex-end;

      .Button &:not(:last-child){
        margin-right: 10px;
      }
    }
  }
}
