@import '../../../../scss/base/var';

.SelectedDirs {
  height: 41px;
  background-color: $color-white;
  padding: 5px 15px 5px 5px;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .SelectedDirs--single-dir {
    display: contents;
    white-space: nowrap;

    .SelectedDirs--dir-name,
    .SelectedDirs--dir-separator {
      cursor: default;
      padding: 0;
    }
  }
}

.SelectedDirs.noBackground {
  background-color: transparent;
  overflow: hidden;
}

/** Special styling if highlightOnHover=true is set */
.SelectedDirs--highlight-on-hover {
  .SelectedDirs--home-icon-container,
  .SelectedDirs--dir-name {
    cursor: pointer !important;

    &:hover{
      background-color: $color-very-light-grey;
    }
  }
}

/** Styling for the last element if highlightLastEntry=true is set */
.SelectedDirs--highlight {
  .SelectedDirs--dir-name {
    color: $color-primary;
    font-weight: 500;
  }
}

/** Show border if showBottomBorder=true is set */
.SelectedDirs--bottom-border {
  border-bottom: 1px solid $color-grey;
}
