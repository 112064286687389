@import '../../../../scss/base/var';

.Wizard {
  padding: 40px 20px;
  height: 100%;
  overflow-y: auto;

  .Wizard--inner {
    max-width: 900px;
    margin: 0 auto;

    .Wizard--headline {
      font-size: 20px;
      display: block;
      margin-bottom: 20px;
    }

    .Wizard--description {
      margin-bottom: 40px;
    }

    .Wizard--buttons {
      border-top: 1px solid $color-grey;
      padding-top: 20px;
      display: flex;
      justify-content: flex-end;

      > * {
        margin-left: 10px;
      }
    }
  }
}
