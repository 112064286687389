@import '../../../../scss/base/var';

.samplingStrategy {

  .automaticStrategy {
    margin-top: 40px;

    .label {
      color: $color-text-light;
      cursor: default;
    }
  }

  .tableStrategy {
    margin-top: 40px;

    .tableSelectContainer {
      max-width: 600px;
    }
  }

  .infoBox {
    margin-top: 20px;
  }
}
