.brush {
  .selection {
    stroke: #537FAF;
    fill: #537FAF;
    fill-opacity: 0.3;
    stroke-width: 1px;
  }
}

.chartAxis {

  path {
    display: none;
  }

  .bottomTicks {
    font-size: 12px;

    line {
      display: none;
    }
    text {
      fill: #979FA8;
    }
  }
}
