// Even though this is located under newDatapoolWizard/optChannels this file contains some styles that are common
//  to several NewDatapool Step and NewAugur Step components that are required for the Campaign Optimization
//  Datapool & Augur

@use "../../../../../../scss/base/theme.module";
@import '../../../../../../scss/base/var';

.optComponent {
  .errorContainer {
    text-align: end;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tableContainer {
    cursor: default;
    margin-bottom: 10px;
    border: 1px solid $color-grey;
    border-radius: 4px;

    .noElements {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    th {
      border-top: none;
    }

    .row {
      &:hover {
        background-color: $color-light-grey;
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .tdNoPadding {
    padding: 10px 5px;
    background-color: $color-light-grey;

    &:last-child {
      border-bottom: None;
    }
  }

  .addContainer {
    .addElement {
      .addElementFields {
        padding: 5px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        font-size: 14px; // Otherwise the font is set to 12px by the .table-reset CSS class

        .addElementField {

        }
      }

      .RemoveIconContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .RemoveIcon {
          height: 20px;
          width: 20px;
          padding: 2px;
          border-radius: 10px;
          background-color: $color-red;
          color: $color-white;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;

          &:hover {
            background-color: $color-red-dark;
          }
        }

      }

    }
  }

  .AddIconContainer {
    padding-right: 6px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .AddIcon {
      height: 22px;
      width: 22px;
      padding: 2px;
      border-radius: 11px;
      background-color: theme.$color-secondary;
      color: $color-white;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: theme.$color-primary;
      }
    }
  }

  .buttonRow {
    margin-bottom: 10px;

    .addButton {
      margin-left: auto;
    }
  }
}

// --- Responsiveness
@media(max-width: $media-lg) {
  .optComponent {
    .addContainer {
      .addElement {
        .addElementFields {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media(max-width: $media-sm) {
  .optComponent {
    .addContainer {
      .addElement {
        .addElementFields {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
