@import '../../../../scss/base/var';

.cancelSubmitButtonsWrapper {

  .componentParent {

  }

  .buttonsParent {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $color-grey;
    display: flex;
    justify-content: flex-end;

    .Button &:not(:last-child){
      margin-right: 10px;
    }
  }
}
