@import '../../../../scss/base/var';

.ErrorBoundary {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;

  &-100vh {
    height: 100vh;
  }

  .ErrorBoundary--picture {
    width: 107px;
    height: 107px;
    margin: 0 auto 25px auto;
    background-size: cover;
    // background: url("../img/orchestration/preQueueEmpty.png") no-repeat; // This must come from the additional class name that is passed!
  }

  .ErrorBoundary--headline {
    font-size: 18px;
    font-weight: 400;
  }

  .ErrorBoundary--description {
    margin-top: 15px;
    font-size: 14px;
    color: $color-text-light;
  }

  .ErrorBoundary--actions {
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
}


