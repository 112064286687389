@import '../../../../../scss/base/var';

:export {
  primaryColor: $color-primary;
  primaryColorHighlight: $color-primary-highlight;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    color: $color-text;
    font-weight: 400;
    margin: 0 0 13px 0;
    font-size: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .chart {
    padding: 5px;
    height: 1px;
    flex-grow: 1;

    &.bordered {
      border: 1px solid $color-grey;
      border-radius: 4px;
    }
  }

  .link {
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

}
