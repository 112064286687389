@import '../../../../../../../../scss/base/var';

.scoreDistributionOverview {
  padding: 26px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .scoreDistributionOverviewLoading {
    position: relative;
    flex-grow: 1;
  }

  .scoreDistributionOverviewHeadline {
    color: $color-text;
    font-weight: 400;
    margin: 0 0 13px 0;
    font-size: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .scoreDistributionOverviewContent {
    padding-bottom: 20px;

    .scoreDistributionPagingHeader {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      .scoreDistributionOverviewButtons {
        margin-left: auto;
        margin-right: 10px;
      }
    }

    .latestScoringContainer {
      .loaded {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        align-items: flex-end;

        .latestScoringChart {
          margin-right: 20px;
          margin-bottom: 20px;
          width: 240px;
          flex-grow: 1;
          flex-shrink: 1;
        }

        .latestScoringDetails {
          margin-right: 20px;
          margin-bottom: 20px;
          flex-shrink: 0;
          flex-grow: 0;
          height: fit-content;

          .detailsLine {
            margin-bottom: 5px;
            cursor: default;

            .detailsKey {
              font-weight: 400;
              margin-right: 5px;
            }

            .detailsValue {
              &.detailsValueActiveModel,
              &.detailsValueAgo {
                margin-left: 5px;
                color: $color-text-light;
              }

              &.detailsValueInactiveModel {
                margin-left: 5px;
                color: $color-orange-dark;
              }
            }
          }
        }
      }

      .error {

      }
    }

    .allScoringRunsContainer {
      margin-top: 40px;

      .allScoringRunsList {
        margin-top: 10px;

        .listLoading {
          position: relative;
          min-height: 200px;
        }

        .listError {

        }

        .listLoaded {
          .listRow {
            display: grid;
            grid-template-columns: 160px 1fr 1fr 140px 30px;

            &.listRowHeadline {
              font-weight: 400;

              &:hover {
                background-color: unset;
              }
            }

            &:hover {
              background-color: $color-light-grey;
            }

            .listColumn {
              padding: 5px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              cursor: default;

              &.listColumnCheckbox {

              }

              &.listColumnCreatedAt {

              }

              &.listColumnInputTable {

              }

              &.listColumnOutputTable {

              }

              &.listColumnModelCode {
                display: flex;
                align-items: center;

                .alertIcon {
                  margin-left: 5px;
                  color: $color-orange-dark;
                }
              }
            }
          }
        }
      }

      .allScoringRunsFooter {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .footerSelectLine {
          display: flex;

          .amountSelected {
            margin-right: 10px;
          }

          .deselectAll {
            margin-right: 10px;
          }

          .deselectAll,
          .selectAll {
            color: $color-primary;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .footerButtonLine {
          margin-top: 20px;
        }
      }
    }
  }
}
