@import '../../../../../scss/base/var';

:export {
  colorText: $color-text;
  colorTextLight: $color-text-light;
  primaryColor: $color-primary;
  secondaryColor: $color-secondary;
  secondaryColorLighter90: $color-secondary-lighter90;
  colorLightGrey: $color-light-grey;
  colorRedDark: $color-red-dark;
}

.NumericSliderInput {

  .NumericSliderInput--header {
    display: flex;

    .NumericSliderInput--label {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      padding: 0 0 6px 0;
      white-space: nowrap;
      cursor: default;
    }

    .NumericSliderInput--error {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }

  .NumericSliderInput--preview {
    margin-bottom: 5px;
  }

  .NumericSliderInput--input {

  }
}
