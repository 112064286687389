@use "../../../../scss/base/theme.module";
@import '../../../../scss/base/var';

.SingleTableStatistics {
  padding: 26px 20px;
  height: 100%;

  .SingleTableStatistics--header {
    .SingleTableStatistics--table-name {
      font-size: 15px;
      font-weight: 400;
      margin-right: 5px;
      vertical-align: middle;
    }

    .SingleTableStatistics--meta-information {
      display: flex;
      flex-direction: row;
      color: $color-text;
      font-weight: 400;
      font-size: 18px;
      margin: 20px 20px 20px 0;

      div {
        height: 24px;
        margin-right: 5px;
        border-radius: 12px;
        padding: 0 12px;
        display: flex;
        align-items: center;
      }
      .icon {
        margin-right: 2px;
      }
    }
  }

  .SingleTableStatistics--table {
    margin-top: 10px;

    .key-label {
      background-color: theme.$color-secondary;
      color: white;
      display: inline-block;
      padding: 4px 8px;
      margin: -4px 0;
      border-radius: 11px;
    }

    .td-key {
      width: 1px;
    }

    tbody tr:hover{
      background-color: $color-blue-grey;
    }

    .SingleTableStatistics--td {
      vertical-align: middle;
      height: 50px;
      white-space: nowrap;
    }
  }
}
