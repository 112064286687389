@import '../../../../../../scss/base/var';

.optCustomerTable {
  .row {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.rowValueSelect,
    &.customerIdsSelect {
      display: flex;
      flex-direction: row;
      width: 100%;

      .column {
        width: 0;
        flex-grow: 1;
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}
