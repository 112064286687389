@import '../../../../scss/base/var';


.animationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
  width: 400px;
  margin-top: -50px;

  .animationSpinner {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1px;
    margin-top: -80px;
  }

  .el {
    position: absolute;
    opacity: 1;
    width: 2px;
    height: 60px;
    max-height: 40vw;
    transform-origin: 50% 100%;
    background: white;
  }

  .pulsing {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 400px;
    background-color: white;

    .svg {
      transform: scale(5);
      position: absolute;
    }
  }
}

