.addl-info-input-parent {
  .addl-info-info-row {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .label-parent {
      margin-right: auto;
    }

    .checkbox-item {
      height: 20px;
      width: 20px;
      margin: 0 15px;
    }

    .text-input-parent {
      width: 40px;
      margin-right: 10px;
    }

    .dropdown-parent {
      margin-right: 10px;
      margin-top: 0;
    }
  }
}

.upload-summary-container {
  margin-top: 40px;
  margin-bottom: 60px;

  .summary-headline-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .summary-headline {
      font-weight: 500;
      font-size: 15px;
      color: $color-primary
    }

    .validity-label {
      color: $color-white;
      height: 18px;
      border-radius: 9px;
      padding: 0 10px;
      margin-left: 20px;

      &.valid {
        background-color: $color-green;
      }

      &.invalid {
        background-color: $color-red;
      }
    }
  }

  .summary-info-container {
    margin-bottom: 20px;

    .info-row {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .checkbox-item {
        height: 20px;
        width: 20px;

        .mark-fixed {
          &:hover {
            background-color: $color-white !important; // disable the background-color change when hovering the unchangeable checkmark
          }
        }
      }

      .text-input-parent {
        width: 40px;
        margin-right: 10px;
      }

      .dropdown-parent {
        margin-right: 10px;
      }
    }
  }

  .commit-error-container {
    color: $color-red;
    margin-bottom: 10px;

    span {
      margin-bottom: 5px;
      display: block;
      font-weight: 400;
    }
  }
}

.keys-input-parent {
  display: flex;
  margin-top: 10px;
  margin-left: -10px;

  .col-list-parent {
    padding: 0 10px;
    width: 33%;

    .list-name {
      font-weight: 400;
      margin-bottom: 5px;
      display: block;
    }

    .draggable-col-list {
      border: 1px solid $color-grey;
      border-radius: 4px;
      padding: 5px;
      height: 200px;
      overflow: auto;

      &.draggable-col-list-disabled {
        background: $color-light-grey;
      }

      .draggable-list-element {
        padding: 5px;
      }
    }
  }
}

.data-input-parent {
  overflow: auto;
  width: 820px; // Otherwise it destroys the whole wizard width. TODO: Find a better way to achieve this instead of the hard-coded value
  margin-top: 10px;
  padding-bottom: 10px; // Make space for the vertical scrollbar
}

.container--data {
  .buckets {
    .buckets-headline-container {
      margin-bottom: 10px;
      display: flex;

      .buckets-headline {
        cursor: default;
        font-weight: 400;
        display: block;
        margin-top: auto;
        flex-grow: 1;
      }
    }

    .buckets-list {
      display: flex;
      flex-direction: column;

      .bucket-link {
        overflow: hidden;

        .bucket-element {
          display: flex;
          justify-content: center;
          flex-direction: column;
          background-color: $color-very-light-grey;
          border: 1px solid $color-light-grey;
          border-radius: 4px;
          padding: 10px;
          margin-bottom: 10px;
          cursor: pointer;

          &:hover {
            background-color: $color-light-grey;
            border: 1px solid $color-grey;
          }

          .bucket-name {
            font-weight: 400;
            margin-bottom: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .bucket-name,
          .bucket-created-at {
            display: block;
          }
        }
      }
    }

    &.buckets-empty-parent {
      position: absolute;
      width: calc(100% - 20px);
      height: calc(100% - 20px);

      .buckets-empty {
        display: flex;
        width: 100%;
        min-height: 100%;
        position: relative;
        align-items: center;
        justify-content: center;

        .buckets-empty-elements {
          display: flex;
          flex-direction: column;
          text-align: center;

          .buckets-empty-pic {
            width: 107px;
            height: 107px;
            margin: 0 auto 25px auto;
            background: url("../../img/bucketsEmpty.png") no-repeat;
            background-size: cover;
          }

          .headline {
            font-size: 18px;
            font-weight: 400;
            padding: 0 0 15px 0;
          }

          .description {
            font-size: 14px;
            color: $color-text-light;
          }
        }
      }
    }
  }

  .back-button-container {
    margin: 0 0 20px 0;

    .back-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;

      .back-icon {
        color: $color-primary-lighter30;
        margin-right: 5px;
      }

      .back-text {
        color: $color-primary-lighter30; // This is to make the color consistent - but doesn't look good.;
      }

      &:hover {
        .back-icon {
          color: $color-primary;
        }

        .back-text {
          color: $color-primary;
        }
      }
    }
  }

  .wizard-parent-upload {
    position: relative;
    height: 100%;

    .error-container {
      display: flex;
      align-items: flex-end;
      color: $color-red;
      margin-bottom: 5px;
    }

    .step-bucket-container,
    .step-path-container,
    .step-keyspace-container,
    .step-table-container,
    .step-upload-container {
      .radio {
        &:not(:first-child) {
          margin-left: 30px;
        }
      }

      .input-parent {
        margin-top: 20px;

        .input-check {
          margin-top: 10px;
        }
      }

      .dropdown-parent {
        margin-top: 20px;
      }

      .radio-parent-concat {
        margin-top: 40px;
      }
    }
  }

  .dialog-container {
    background-color: $color-white;
    padding: 40px 100px 20px 100px;

    .dialog-inner-container {
      width: 100%;
    }
  }
}

.data-source-parent {
  width: 100%;
  // min-height: calc(100vh - 62px - 48px - 40px); // 62px top bar, 48 px main tab bar, 40px=2*20px vertical margin
  height: 100%;
  padding: 20px 15px;
  background: white;
  border-radius: 4px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  display: flex;
  flex-direction: column;

  .data-source-headline {
    display: flex;

    .data-source-description {
      flex-grow: 1;

      .data-source-name {
        cursor: default;
        display: block;
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 400;
      }

      .data-source-type {
        cursor: default;
        display: block;
        color: $color-text-light;
        font-size: 14px;
      }
    }

    .headline-buttons {
      .button {
        margin-left: 10px;
      }
    }
  }

  .data-source-body {
    margin-top: 20px;
    position: relative;
    width: 100%;
    height: 0; // flex-grow will take care of hhe full height
    flex-grow: 1;

    .data-source-body-tabs {
      display: flex;
      flex-direction: column;
      height: 100%;

      .data-source-body-tabs-content {
        height: 0;
        flex-grow: 1;
        overflow-y: auto;
      }
    }

    .keyspaces {
      .keyspaces-header {
        display: flex;
        margin-bottom: 10px;

        .keyspaces-headline {
          cursor: default;
          margin-top: auto;
          font-weight: 400;
          display: block;
          flex-grow: 1;
        }
      }
    }

    .bucket-content {
      .bucket-content-header {
        display: flex;

        .back-button-container {
          flex-grow: 1;
        }
      }
    }
  }
}

.cassandra-tables {
  .tables-header {
    display: flex;

    .back-button-container {
      flex-grow: 1;
    }
  }

  .tables-headline {
    cursor: default;
    margin-bottom: 10px;
    font-weight: 400;
    display: block;
  }

  .tables-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .table-element-wrapper {
      flex: 0 50%;
      width: 100%;
      overflow: hidden;

      &:nth-child(2n+1) {
        padding-right: 5px;
      }

      &:nth-child(2n) {
        padding-left: 5px;
      }

      .table-element {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: $color-very-light-grey;
        border: 1px solid $color-light-grey;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;
        //width: 100%;

        &:hover {
          background-color: $color-light-grey;
          border: 1px solid $color-grey;
        }

        .table-name {
          text-overflow: ellipsis;
          overflow: hidden;
          // font-weight: 400;
          // margin-bottom: 5px;
        }
      }
    }
  }
}

.cassandra-table-browser {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .table-browser-header {
    display: flex;

    .back-button-container {
      flex-grow: 1;
    }

    .headline-buttons {
      .button {
        margin-left: 10px;
      }
    }
  }

  .table-browser-headline {
    cursor: default;
    margin-bottom: 10px;
    font-weight: 400;
    display: block;
  }

  .TextInputArea--error {
    margin: 10px 0;
    padding: 10px;
    color: $color-red;
    border: 1px solid $color-red;
    background-color: $color-red-light;
    border-radius: 4px;
    text-align: center;
    cursor: default;
  }
}
