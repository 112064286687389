@import '../../../../../scss/base/var';

.vscodeIframe {
  border-radius: 5px 0 5px 5px;
}

.buttons {
  position: absolute;
  border: 1px solid #ddd;
  top: -32px;
  right: -1px;
  border-radius: 3px 3px 0 0;
  height: 32px;
  z-index: 1000;
  display: flex;
  overflow: hidden;

  &.isFullscreen {
    top: 0;
    border-radius: 0 0 3px 3px;
  }
}

.editorToggleContainer {
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-text;
  background-color: $color-white;
  border-right: 1px solid $color-grey;
  z-index: 1;
}

.fullscreenButton {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-text;
  background-color: $color-white;
  z-index: 1;

  &:hover{
    background-color: $color-grey;
    color: $color-white;
  }
}
