@import '../../../../../scss/base/var';

.inputRow {
  display: flex;
  align-items: flex-end;
  padding-top: 20px;
  width: 100%;

  .inputParent {
    width: 100%;
  }
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  .buttonLine {
    display: flex;
  }
}

.Error {
  color: $color-red;
}
