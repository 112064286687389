@import "../../../../../scss/base/var";

.cellInfoFlap {
  width: 40px;
  background-color: $color-light-grey;
  margin-right: 15px;
  min-width: 40px;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  // border: 1px solid $color-grey !important;
  border-left: 0 !important;
  font-family: $font-monospace !important;
  font-size: 12px;
  color: $color-text;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.05s ease-in-out;
  overflow: hidden;
  position: relative;
  flex-grow: 0;

  > p {
    margin: 0;
    padding: 0;
  }

  .executionCountExecute {
    display: none;
  }

  &:hover {
    .executionCount {
      display: none;
    }

    .executionCountExecute {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .cellInfoFlapExecutingOverlay {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: $color-dark-grey;
    position: absolute;
    top: calc(50% - 4px);
    left: -8px;
  }
}

.cellInfoFlapSelected {
  background-color: $color-primary;
  color: $color-white;

  .cellInfoFlapExecutingOverlay {
    background-color: $color-white;
  }
}
