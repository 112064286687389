@import '../../../../scss/base/var';

.CassandraTablePreview {
  height: 100%;
  display: flex;
  flex-direction: column;

  .CassandraTablePreview--headline {
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .CassandraTablePreview--table {
    overflow: scroll; // Always show the scrollbar
    //padding: 0 10px 10px 0; // Make space for the scrollbar

    table {

      thead {
        position: sticky;
        top: -1px; // For top border, which will still scroll outside, due to because of border-collapse: collapse; but it helps a bit
        z-index: 10;

        th {
          cursor: default;

          .col-name,
          .col-type {
            font-weight: 400;
            display: block;
          }

          .col-name {
            font-size: 14px;
            color: $color-text;
            margin-bottom: 5px;
          }

          .col-type {
            color: $color-text-light;

            &.col-type-editing {
              min-width: 120px;
            }
          }
        }
      }

      td {
        white-space: nowrap;
        cursor: default;
      }

      input {
        height: 24px !important;
        padding: 0 7px !important;
      }
    }
  }

  .CassandraTablePreview--bottom {
    margin-top: 5px;
    color: $color-text-light;
  }
}
