@use '../../../../../scss/base/theme.module';
@import '../../../../../scss/base/var';

.startServer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .busy {
    path {
      fill: $color-text-light;
    }
  }
}

.startServerParent {
  width: 800px;
  background-color: white;
  border: 1px solid $color-grey;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
}

.startServerHeadlineParent {
  display: flex;
  justify-content: center;
  margin-bottom: auto;
  // height: 60px;
}

.startServerHeadline {
  font-size: 18px;
  font-weight: 400;
}

.startServerMessageParent {
  display: flex;
  justify-content: center;
  height: 20px;
  margin-top: 20px;
}


.startServerButtonParent {
  margin-top: 20px;
}

.imageSelectParent {
  margin-bottom: 40px;
  max-width: 400px;
}

.imageSelectHeadline {
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 15px;
  display: block;
  cursor: default;
}

.resourcesSelectParent {
  margin-bottom: 40px;
}

// ---

.startServerButton {
  margin-top: 20px;
  height: 80px;
  position: relative;

  &.disabled {
    .start-server-button--button {
      opacity: 0.25;
      cursor: default;
    }
  }
}

.startServerButtonButton {
  background: theme.$color-secondary;
  cursor: pointer;
  height: 40px;
  width: 200px;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 20px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: $color-green;
  }
}

.startServerButtonText {
  font: $Roboto;
  font-weight: 300;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-52%);
  left: 0;
  right: 0;
}

.startServerButtonProgressBar {
  position: absolute;
  height: 10px;
  width: 0;
  right: 0;
  top: 50%;
  left: 50%;
  border-radius: 200px;
  transform: translateY(-50%) translateX(-50%);
  background: theme.$color-secondary;
}

.startServerButtonLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 0;

  svg {
    width: 80px;
    height: 80px;
  }
}
